$btn-color: #17a0b4 !important;
$fontsize: 12px !important;
$btntextcolor: white !important;
$btnHeight: 28px !important;
$inputHeight:30px !important;
$inputfontsize:11px !important;
$primary:#004bff;
// $modalHeaderTitleFont: ;
@mixin modalheaderTtext {
  font-size: 14px;
  font-weight: 600;
}
