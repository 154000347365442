@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "./variables.scss";

html,
body {
  height: 100%;
  margin: 0;
  // font-family: Montserrat_interface_font !important;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}
@font-face {
  font-family: 'Montserrat';
  src: url('assets/font/Montserrat-Regular.ttf');
}

.p-t-xs,
.p-y-xs {
  padding-top: 0.25rem !important;
}

.p-a {
  padding: 1rem !important;
}

.box-shadow,
.box-shadow .box,
.box-shadow .box-color {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.03), 0 1px 0 rgba(0, 0, 0, 0.03);
}

.app-content {
  box-shadow: none;
}

.app-header {
  position: fixed;
  top: 0;
  z-index: 1020;
}

.app-footer,
.app-header {
  left: 0;
  right: 0;
  margin: inherit;
}

.app-footer {
  position: absolute;
  bottom: 0;
  z-index: 1;
}

.text-xs {
  font-size: 0.75rem;
}

.p-2 {
  padding: 0.5rem 0.5rem !important;
}

.text-muted {
  //color: inherit!important;
  opacity: 0.6;
}

.pull-right {
  float: right;
}

.m-l-sm,
.m-x-sm {
  margin-left: 0.5rem !important;
}

.m-r-sm,
.m-x-sm {
  margin-right: 0.5rem !important;
}

.navbar {
  background: inherit;
  min-height: 3.5rem;
}

.p-0 {
  padding: 0 0 !important;
}

.dark-white,
.white {
  background-color: #fff;
}

.p-a-sm {
  padding: 0.5rem !important;
}

.m-b-xs,
.m-y-xs {
  margin-bottom: 0.25rem !important;
}

.box,
.box-color {
  background-color: #fff;
  position: relative;
  margin-bottom: 1.5rem;
}

.box-header {
  position: relative;
}

.app-header ~ .app-body {
  padding-top: 3.5rem;
}

.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap;
}

.dropdown-menu > li:not(.dropdown-item) > a {
  display: block;
  padding: 3px 20px;
}

a {
  cursor: pointer;
  outline: 0;
}

.dropdown-menu {
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-transform-origin: top left;
  transform-origin: top left;
  list-style: none;
}

.buttons-right {
  display: flex;
  justify-content: flex-end;
}

*::-webkit-scrollbar {
  width: 0.6em;
  height: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}

.b-r {
  border-right: 1px solid rgba(120, 130, 140, 0.13);
}

.inline {
  display: inline-block;
}

.p-r,
.p-x {
  padding-right: 1rem !important;
}

.p-l,
.p-x {
  padding-left: 4px !important;
}

.text-md {
  font-size: 1.125rem;
}

.scroller {
  display: block;
  cursor: pointer;
  margin-top: 15px;
}

.rightScroll {
  float: right;
  color: #f77a99;
}

.p-r,
.p-x {
  padding-right: 1rem !important;
}

.text-muted {
  opacity: 0.6;
}

.text-xs {
  font-size: 0.75rem;
}

.text-muted {
  color: #6c757d !important;
}

.md-btn,
.pointer,
.ui-check,
.ui-switch {
  cursor: pointer;
}

.nav-md .nav-link {
  padding: 0.5rem 1rem;
}

.nav,
.nav-item,
.nav-link {
  border: inherit;
}

.nav-link,
.nav-tabs {
  position: relative;
}

.nav-link {
  display: block;
  padding: 0.5em 1em;
}

a {
  cursor: pointer;
  outline: 0;
}

.navbar-brand:hover,
.pagination > li > a,
a,
a:focus,
a:hover {
  text-decoration: none;
}

a,
a:focus,
a:hover,
button,
pre {
  color: inherit;
}

.tabitem {
  display: table-cell;
  position: relative;
  cursor: grab;
  cursor: -webkit-grab;
  vertical-align: middle;
}

.nav-active-border .nav-link.active:before,
.nav-active-border .nav-link:focus:before,
.nav-active-border .nav-link:hover:before {
  left: 0;
  right: 0;
  border-bottom-color: inherit;
}

// .info {
//     background-color: #6887ff;
// }
.black,
.danger,
.dark,
.grey,
.info,
.primary,
.success,
.warn,
.warning {
  color: rgba(255, 255, 255, 0.87);
}

a,
a:focus,
a:hover,
button,
pre {
  color: inherit;
}

.success {
  background-color: #6cc788;
}

.row [class*="col-"] {
  padding-left: 12px;
  padding-right: 12px;
}

.text-muted {
  color: inherit !important;
  opacity: 0.6;
}

.pull-right {
  float: right;
}

.m-b-sm,
.m-y-sm {
  margin-bottom: 0.5rem !important;
}

.vt-card:hover,
.vt-card:focus {
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.2);
  // z-index: 1;
}

.vt-card.card-block {
  padding: 0.6rem;
  box-sizing: inherit;
}

.btn.btn-default,
.btn.btn-secondary,
.btn.white,
.card,
blockquote,
pre {
  border-color: rgba(120, 130, 140, 0.13);
}

.blue-100,
.blue-200,
.blue-300,
.blue-400,
.blue-50 {
  color: rgba(0, 0, 0, 0.87);
}

.text-center {
  text-align: center;
}

.card-block {
  flex: 1 1 auto;
  padding: 1.25rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.blue-50 {
  background-color: #e3f2fd;
}

.b-info {
  border-color: #6887ff;
}

.b-success {
  border-color: #6cc788;
}

.b-r {
  border-right: 1px solid rgba(120, 130, 140, 0.13);
}

.inline {
  display: inline-block;
}

.p-r,
.p-x {
  padding-right: 1rem !important;
}

.p-l,
.p-x {
  padding-left: 4px !important;
}

.ellipsisfull {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100% !important;
}

.left-navbar .nav-link.active {
  border-left: 2px #f77a99 solid !important;
  color: #f77a99 !important;
}

.ellipsisfull:hover {
  overflow: hidden;
}

.flex-right {
  justify-content: flex-end;
  display: flex;
}

.text-xs {
  font-size: 0.75rem;
}

.m-b-sm,
.m-y-sm {
  margin-bottom: 0.5rem !important;
}

.b-warning {
  border-color: #f77a99;
}

:root .leftScroll {
  float: left;
  color: #f77a99;
}

.p-t-xs,
.p-y-xs {
  padding-top: 0.25rem !important;
}

.p-a {
  padding: 1rem !important;
  padding-top: 1rem;
}

.btn:not([disabled]).active,
.btn:not([disabled]):focus,
.btn:not([disabled]):hover {
  box-shadow: inset 0 -10rem 0 rgba(158, 158, 158, 0.2);
}

.btn-rounded {
  border-radius: 50px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.input-group-btn:last-child > .btn {
  border-radius: 50px;
}

.input-group-btn:not(:first-child) > .btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.table-striped > tbody > tr:nth-child(2n + 1) {
  background-color: rgba(0, 0, 0, 0.025);
  background-clip: padding-box;
}

table {
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
}

.m-b-sm,
.m-y-sm {
  margin-bottom: 0.5rem !important;
}

.b-warning {
  border-color: #f77a99;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.align-center,
.search-form {
  align-items: center;
}

.clickable {
  cursor: pointer;
}

.toggle-show-right {
  position: fixed;
  z-index: 2;
  right: 0;
  background-color: #fff;
  padding: 10px 12px;
  box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.4);
  border-radius: 4px 0 0 4px;
  letter-spacing: 0.02em;
  font-weight: 300;
  overflow-x: hidden;
  transition: 0.5s;
}

.panel-right-filter[_ngcontent-pek-c3] {
  position: inherit;
  right: 0px;
  z-index: 100;
  height: auto;
}

.filter-parent-height[_ngcontent-pek-c3] {
  background: #ffffff;
  padding: 0;
  height: calc(100vh - 242px);
}

.btn-xs {
  padding: 0.2195rem 0.5rem;
  font-size: 0.8rem;
  // border-radius: 500px;
  padding-left: 1.2em;
  padding-right: 1.2em;
}

// .btn-xs {
//   padding: 0.2195rem 0.5rem;
//   font-size: 0.8rem;
//   border-radius: 500px;
//   padding-left: 1.2em;
//   padding-right: 1.2em;
// }
.btn.b-info:hover {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #6887ff;
}

.btn.b-success:hover {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #6cc788;
}

.btn.disabled,
.btn:disabled {
  cursor: not-allowed;
}

.ui-check > i {
  width: 16px;
  height: 16px;
  line-height: 1;
  box-shadow: 0 0 1px rgba(120, 130, 140, 0.35);
  margin-top: -2px;
  display: inline-block;
  margin-right: 4px;
  background-clip: padding-box;
  position: relative;
  border: 1px solid;
}

.ui-check > i,
.ui-check > span {
  margin-left: -20px;
}

.easyPieChart canvas,
.navbar .nav-text,
.sl-icon > i,
.ui-check > i {
  vertical-align: middle;
}

.ui-check {
  position: relative;
  padding-left: 20px;
  cursor: pointer;
}

.ui-check input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.ui-check input:checked + i:before {
  left: 4px;
  top: 4px;
  width: 6px;
  height: 6px;
  background-color: #0cc2aa;
}

.ui-check input:checked + span .active {
  display: inherit;
}

.ui-check input[type="radio"] + i,
.ui-check input[type="radio"] + i:before {
  border-radius: 50%;
}

.ui-check input[disabled] + i,
fieldset[disabled] .ui-check input + i {
  border-color: rgba(134, 143, 152, 0.2);
}

.ui-check input[disabled] + i:before,
fieldset[disabled] .ui-check input + i:before {
  background-color: rgba(134, 143, 152, 0.2);
}

.ui-check > i {
  width: 16px;
  height: 16px;
  line-height: 1;
  box-shadow: 0 0 1px rgba(120, 130, 140, 0.35);
  margin-left: -20px;
  margin-top: -2px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
  background-clip: padding-box;
  position: relative;
}

.ui-check > i:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 0px;
  height: 0px;
  background-color: transparent;
}

.ui-check > span {
  margin-left: -20px;
}

.ui-check > span .active {
  display: none;
}

.ui-check.ui-check-color input:checked + i:before {
  background-color: #fff;
}

.ui-check-md input:checked + i:before {
  left: 6px;
  top: 6px;
}

.ui-check-md > i {
  width: 18px;
  height: 18px;
}

.ui-check-lg input:checked + i:before {
  width: 12px;
  height: 12px;
  left: 9px;
  top: 9px;
}

.ui-check-lg > i {
  width: 30px;
  height: 30px;
}

.app-content::after,
.arrow:after,
.arrow:before,
.list-body:after,
.list-item::after,
.navside .nav li a::after,
.pager::after,
.ui-check > i:before {
  content: "";
}

.nav-link,
.nav-tabs {
  position: relative;
}

.filter-box[_ngcontent-cio-c3] {
  box-shadow: 0 0 0px rgba(0, 0, 0, 0);
  height: calc(100vh - 264px);
  overflow-x: hidden;
  overflow-y: auto;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
}

.loading-bar {
  display: inline-block;
  width: 4px;
  height: 18px;
  border-radius: 4px;
  animation: loading 1s ease-in-out infinite;
}

.loading-bar:nth-child(1) {
  background-color: #3498db;
  animation-delay: 0;
}

.loading-bar:nth-child(2) {
  background-color: #c0392b;
  animation-delay: 0.09s;
}

.loading-bar:nth-child(3) {
  background-color: #f1c40f;
  animation-delay: 0.18s;
}

.loading-bar:nth-child(4) {
  background-color: #27ae60;
  animation-delay: 0.27s;
}

.top-navbar .nav-link.active {
  border-bottom: 2px #f77a99 solid !important;
  color: #f77a99 !important;
}

.left-navbar .nav-link.active {
  border-left: 2px #f77a99 solid !important;
  color: #f77a99 !important;
}

@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media all and (max-width: 768px) and (orientation: portrait) {
  /* For mobile phones: */
  // [class*="col-"] {
  //     max-width: 100%;
  //   }
  .col-2 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .field-data {
    .col-3 {
      flex: 0 0 50.333333%;
      max-width: 50%;
    }

    .col-1 {
      flex: 0 0 50.333333%;
      max-width: 50%;
    }

    .col-2 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  .col-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

.toggle-show-right {
  position: fixed;
  //top: 199px;
  z-index: 2;
  right: 0;
  background-color: #fff;
  padding: 10px 12px;
  box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.4);
  border-radius: 4px 0 0 4px;
  letter-spacing: 0.02em;
  font-weight: 300;
  overflow-x: hidden;
}

.hidden-xs {
  display: block !important;
}

.mat-tab-label .mat-tab-label-content {
  font-size: 13px;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  // background-color: #f77a99 !important;
  // left: 12px !important;
  margin-left: 11px;
}

.mat-expansion-panel-body {
  padding: 5px !important;
}

:focus {
  outline: 0 !important;
}

.nav-active-border .nav-link::before {
  content: "";
  position: absolute;
  transition: all 0.2s ease-in-out;
  left: 50%;
  right: 50%;
  bottom: 0;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-bottom-color: transparent;
}

.nav-link {
  border: inherit;
}

.fa-remove::before,
.fa-close::before,
.fa-times::before {
  content: "\f00d";
}

.navside {
  box-sizing: inherit;
}

[type="reset"],
[type="submit"],
button,
html [type="button"] {
  -webkit-appearance: button;
}

.box-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

// .row {
//   display: flex;
//   flex-wrap: wrap;
//   margin-right: -15px;
//   margin-left: -15px;
// }

.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.btn {
  font-weight: 500;
  outline: 0 !important;
}

.t-a-l {
  text-align: left;
}

.table-striped {
  font-weight: 400;

  line-height: 1.5;
}

.p-b-xs,
.p-y-xs {
  padding-bottom: 0.25rem !important;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th {
  padding-left: 16px;
  padding-right: 16px;
  border-color: rgba(120, 130, 140, 0.045);
}

.p-t-xs,
.p-y-xs {
  padding-top: 0.25rem !important;
}

.filter-box {
  box-shadow: 0 0 0px rgba(0, 0, 0, 0);
  height: calc(100vh - 264px);
  overflow-x: hidden;
  overflow-y: auto;
}

.disclose {
  display: none;
}

[type="reset"],
[type="submit"],
button,
html [type="button"] {
  -webkit-appearance: button;
}

.align-center,
.search-form {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@keyframes loading {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(1, 2.2);
  }

  40% {
    transform: scale(1);
  }
}

.corner-ribbon {
  position: relative;
  /*top: -14px;
    left: -14px;*/
  background: #169c2b;
  color: #fff;
  text-transform: uppercase;
  //padding: 2px 12px 2px 4px;
}

.corner-ribbon:after {
  content: " ";
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-right: 10px solid #fff;
  border-bottom: 10px solid transparent;
}

.btn:disabled {
  cursor: not-allowed;
  opacity: 0.65;
}

.tabContainer {
  height: auto !important;
}

.tabContainer .leftScroll {
  float: left;
  color: #31719b;
}

.tabContainer .leftScroll .btn {
  background: #ffffff;
  color: #31719b;
}

.tabContainer .leftScroll .btn:hover {
  color: #ff6600;
}

.tabContainer .leftScroll:focus {
  outline: solid #ff9900 1px !important;
  outline-offset: -4px;
}

.tabContainer .rightScroll {
  float: right;
  color: #31719b;
}

.tabContainer .rightScroll .btn {
  background: #ffffff;
  color: #31719b;
}

.tabContainer .rightScroll .btn:hover {
  color: #ff6600;
}

.tabContainer .rightScroll:focus {
  outline: solid #ff9900 1px !important;
  outline-offset: -4px;
}

.tabContainer .scroller {
  display: block;
  cursor: pointer;
}

.tabWrapper {
  // width: 1300px;
  overflow: hidden;
}

.tabsSet {
  left: 0px;
  min-width: 3000px;
  margin-left: 1px;
}

.rightScroll {
  position: absolute;
  left: 1326px;
  z-index: 99999999;
  width: 40px;
  height: 27px;
  margin-top: 12px;
  background: white;
}

.tabContainer .nav-tabs {
  border-bottom: none;
}

/* line 3221, ../sass/common.scss */
.tabContainer .nav-item {
  display: table-cell;
  cursor: default;
  width: 150px;
  margin-top: 2px;
}

/* line 3229, ../sass/common.scss */
.tabContainer .nav-link {
  padding: 7px 5px;
  height: 31px;
  color: #31719b !important;
  border: 1px solid #31719b !important;
  background-color: #bad7ea;
  margin-right: 3px;
  top: 1px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
}

@media only screen and (min-width: 600px) {
}

@media all and (max-width: 768px) and (orientation: portrait) {
  .col-2 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .field-data {
    .col-3 {
      flex: 0 0 50.333333%;
      max-width: 50%;
    }

    .col-1 {
      flex: 0 0 50.333333%;
      max-width: 50%;
    }

    .col-2 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  .col-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 992px) {
}

@media only screen and (min-width: 1200px) {
}

.myCustomModalClass .modal-dialog {
  max-width: 97%;
  max-height: 60%;
  height: 60%;
  margin: auto;
  padding: 0 !important;
}

.myCustomModalClasslabadmin .modal-dialog {
  max-width: 99%;
  max-height: 99%;
  height: 99%;
  margin: auto;
  padding: 6px 0px 0px 0px !important;
  overflow-x: hidden;
  overflow-y: hidden; 
  //margin-bottom: 5px !important;

}


/*login page */
.welcome-header {
  font-size: 20px;
  position: relative;

  text-align: right;
  font-weight: 600;
}

.main-title {
  font-family: "Roboto", "Noto", sans-serif;
  font-size: 20px;
  font-weight: 600;
}

.logo-Img {
  margin: 2px 10px 2px 0px;
}

.info,
.login {
  margin-top: 88px;
  margin-bottom: 3%;
}

.welcomeVL {
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  font-size: 36px;
  font-weight: 600;
  margin: 0px;
}

.basicInfoVL {
  text-align: center;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.75);
  font-weight: 600;
}

.login-header {
  font-size: 20px;
  position: relative;
  padding-top: 36px;
  padding-bottom: 30px;
  text-align: center;
  font-weight: 600;
}

.loginPB {
  margin-top: 15px;
  margin-bottom: 12px;
  font-size: 14px;
}

.formStyle {
  position: relative;
  margin-left: 10%;
  margin-right: 10%;
}

.loginBox {
  width: 300px;
  height: 366px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  border-radius: 8px;
}

.loginTrans {
  width: 240px;
}

.loginbtncustom {
  background-color: white;
  color: black;
}

.loginboxbackgrd {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.modal-content-attribute {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  width: 50%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  left: 25%;
  top: 20%;
}

// .autocomplete-container .input-container .x {
//   position: absolute;
//   right: 10px;
//   margin: auto;
//   cursor: pointer;
//   top: 40%;
//   -webkit-transform: translateY(-50%);
//   transform: translateY(-50%);
//   // opacity: 0;
// }

// .autocomplete-container .input-container input {
//     font-size: 14px !important;
//     box-sizing: border-box!important;
//     border: none!important;
//     box-shadow: none!important;
//     outline: 0!important;
//     background-color: #fff!important;
//     color: rgba(0,0,0,.87)!important;
//     width: 100%!important;
// padding: 0 0px!important;
//     line-height: 40px!important;
//     height: 30px!important;
// }

// ::ng-deep.autocomplete-container .input-container input{
//   padding-right: 38px !important;
// }

.btnspace {
  margin-left: 5px;
}

.containercus {
  max-width: 100%;
}

.inputField {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 65%;
  border: none;
  //  margin-top:10px;
  // background-color: #f0f0f0;
}

.inputFielderror {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: none;
}

.even {
  color: #17a2b8;
}

.odd {
  color: gray;
}

.inputField-guid {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80px;
  border: none;
  //  margin-top:10px;
  //   background-color:#117a8b;
}

.mat-card-header-text {
  margin: 0px 0px;
}

.txtalign {
  text-align: left;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #17a2b8;
}

mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #17a3b883 !important;
}

.tb_successclr {
  width: 10px;
  height: 80px;
  background-color: aquamarine;
}

.tb_success {
  width: 100px;
  height: 55px;
  border: none;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin: 2px;
  margin-top: 23px;
}

.tb_progress {
  width: 100px;
  height: 55px;
  margin-left: 10px;
  margin: 2px;
  border: none;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-top: 23px;
}

.tb_progressclr {
  width: 10px;
  height: 80px;
  background-color: yellow;
}

.tb_failure {
  width: 100px;
  height: 55px;
  margin-left: 10px;
  margin: 2px;
  border: none;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-top: 23px;
}

.tb_failureclr {
  width: 10px;
  height: 80px;
  background-color: #fb8989;
}

.textalignmsgcnt {
  text-align: left;
  padding-top: 10px;
  // padding-left: 5px;
}

.example-card {
  max-width: 300px;
  margin: 4px;
}

.autocomplete-container {
  position: relative !important;
}

.successtriangle {
  width: 5px;
  height: 92px;
  background-color: aquamarine;
  position: absolute;
}

.inprogresstriangle {
  width: 5px;
  height: 92px;
  background-color: yellow;
  position: absolute;
}

.failedtriangle {
  width: 5px;
  height: 92px;
  background-color: #fb8989;
  position: absolute;
}

.mat-list-base {
  padding-top: 0 !important;
  // padding-top: 50px !important;
}

.myModalTemplate .modal-dialog {
  // margin: 77px 80px 20px 190px;
  max-width: 71%;
  max-height: 77%;
  // height:50%;
}

// .myModalTemplate .modal-dialog {
//   max-width: 31%;
//   max-height:31%;
//  // height:50%;
// }

.highlight {
  background-color: #f0f0f0 !important;
}

.successtrianglestage {
  // border-top: 2vh solid aquamarine;
  // border-right:1vw solid transparent;
  //  position: absolute;
  border: 0.5vh solid #0dbd36;
}

.inprogresstrianglestage {
  // border-top: 2vh solid yellow;
  //  border-right: 1vw solid transparent;
  //  position: absolute;
  border: 0.5vh solid yellow;
}

.failedtrianglestage {
  // border-top: 2vh solid #fb8989;
  // border-right: 1vw solid transparent;
  // position: absolute;
  border: 0.5vh solid #fb8989;
}

//changes for  mappings

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: -1%;
  margin-top: 30px;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu > a:after {
  display: block;
  content: "";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-right: -10px;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -1%;
}

// .dropdown-menu{
//     transform: translate3d(0px, 0px, 0px);
// }
ul.token-input-list-facebook {
  overflow: hidden;
  height: auto !important;
  height: 1%;
  width: 470px;
  border: 1px solid #8496ba;
  cursor: text;
  font-size: 15px;
  font-family: Verdana;
  min-height: 1px;
  z-index: 999;
  margin: 0;
  padding: 0;
  background-color: #fff;
  list-style-type: none;
  clear: left;
}

/* Example tokeninput style #2: Facebook style */
ul.token-input-list-facebook {
  overflow: hidden;
  height: auto !important;
  height: 1%;
  width: 470px;
  border: 1px solid #8496ba;
  cursor: text;
  font-size: 15px;
  font-family: Verdana;
  min-height: 1px;
  z-index: 999;
  margin: 0;
  padding: 0;
  background-color: #fff;
  list-style-type: none;
  clear: left;
}

ul.token-input-list-facebook li input {
  border: 0;
  width: 800px;
  padding: 3px 8px;
  background-color: white;
  margin: 2px 0;
  -webkit-appearance: caret;
}

li.token-input-token-facebook {
  overflow: hidden;
  height: auto !important;
  height: 15px;
  margin: 3px;
  padding: 1px 3px;
  background-color: #eff2f7;
  color: #000;
  cursor: default;
  border: 1px solid #ccd5e4;
  font-size: 15px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  float: left;
  white-space: nowrap;
}

li.token-input-token-facebook p {
  display: inline;
  padding: 0;
  margin: 0;
}

li.token-input-token-facebook div {
  color: #a6b3cf;
  margin-left: 5px;
  font-weight: bold;
  cursor: pointer !important;
  z-index: 999;
}

li.token-input-selected-token-facebook {
  background-color: #5670a6;
  border: 1px solid #3b5998;
  color: #fff;
}

li.token-input-input-token-facebook {
  float: left;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

div.token-input-dropdown-facebook {
  position: absolute;
  width: 800px;
  background-color: #fff;
  overflow: hidden;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  cursor: default;
  font-size: 15px;
  font-family: Verdana;
  z-index: 1;
}

div.token-input-dropdown-facebook p {
  margin: 0;
  padding: 5px;
  font-weight: bold;
  color: #777;
}

div.token-input-dropdown-facebook ul {
  margin: 0;
  padding: 0;
}

div.token-input-dropdown-facebook ul li {
  background-color: #fff;
  padding: 3px;
  margin: 0;
  list-style-type: none;
}

div.token-input-dropdown-facebook ul li.token-input-dropdown-item-facebook {
  background-color: #fff;
}

div.token-input-dropdown-facebook ul li.token-input-dropdown-item2-facebook {
  background-color: #fff;
}

div.token-input-dropdown-facebook ul li em {
  font-weight: bold;
  font-style: normal;
}

div.token-input-dropdown-facebook ul li.token-input-selected-dropdown-item-facebook {
  background-color: #3b5998;
  color: #fff;
}

/* Example tokeninput style #1: Token vertical list*/
ul.token-input-list {
  overflow: hidden;
  height: auto !important;
  height: 1%;
  width: 280px;
  border: 1px solid #999;
  cursor: text;
  font-size: 15px;
  font-family: Verdana;
  z-index: 999;
  margin: 0;
  padding: 0;
  background-color: #fff;
  list-style-type: none;
  clear: left;
}

ul.token-input-list li {
  list-style-type: none;
}

ul.token-input-list li input {
  border: 0;
  width: 250px;
  padding: 3px 8px;
  background-color: white;
  -webkit-appearance: caret;
}

li.token-input-token {
  overflow: hidden;
  height: auto !important;
  height: 1%;
  margin: 2px;
  padding: 2px 4px;
  /*background-color: #d0efa0;*/
  background-color: #dbe9f3;
  color: #000;
  font-weight: lighter;
  cursor: default;
  display: block;
}

li.token-input-token p {
  float: left;
  padding: 0;
  margin: 0;
}

li.token-input-token div {
  float: right;
  color: #777;
  cursor: pointer !important;
  z-index: 999;
}

li.token-input-selected-token {
  background-color: #08844e;
  color: #fff;
}

li.token-input-selected-token div {
  color: #bbb;
}

div.token-input-dropdown {
  position: absolute;
  width: 260px;
  background-color: #fff;
  overflow: hidden;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  cursor: default;
  font-size: 12px;
  font-family: Verdana;
  z-index: 1;
}

div.token-input-dropdown p {
  margin: 0;
  padding: 5px;
  font-weight: lighter;
  color: #777;
}

div.token-input-dropdown ul {
  margin: 0;
  padding: 0;
}

div.token-input-dropdown ul li {
  background-color: #fff;
  padding: 3px;
  list-style-type: none;
}

div.token-input-dropdown ul li.token-input-dropdown-item {
  background-color: #fafafa;
}

div.token-input-dropdown ul li.token-input-dropdown-item2 {
  background-color: #fff;
}

div.token-input-dropdown ul li em {
  font-weight: lighter;
  font-style: normal;
}

div.token-input-dropdown ul li.token-input-selected-dropdown-item {
  background-color: #dbe9f3;
}

.caret.caret-up {
  border-top-width: 0;
  border-bottom: 4px solid #fff;
}

#alertMsg {
  font-weight: bold;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #17a2b8 !important;
}

.textboxbordernone {
  width: 100%;
  //  border: none;
}

ul,
#idTemplateXmlDiv,
#idMappedXmlDiv {
  list-style-type: none;
}

#myUL {
  margin: 0;
  padding: 0;
}

.caret {
  cursor: pointer;
  -webkit-user-select: none;
  /* Safari 3.1+ */
  -moz-user-select: none;
  /* Firefox 2+ */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
}

.caret::before {
  content: "\25B6";
  color: black;
  display: inline-block;
  margin-right: 6px;
}

.caret-down::before {
  -ms-transform: rotate(90deg);
  /* IE 9 */
  -webkit-transform: rotate(90deg);
  /* Safari */
  transform: rotate(90deg);
}

.nested {
  display: none;
}

.active {
  display: block;
}

// .headerdark
// {
//   background-color: #343a40 !important;
// }
.multiselect-dropdown {
  position: relative;
  width: 100%;
  font-size: inherit;
  font-family: inherit;
  background-color: white;
}
.selected-item.ng-star-inserted {
  max-width: 100% !important;
}
.editor-container {
  margin: 5px;
  width: 100%;
  height: 320px;
}

input[type="file"] {
  // font-size: 100px;
  width: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  overflow: hidden;
}

.textUserName {
  color: black;
  background-color: #17a2b84d;
  margin-top: 4px;
  padding-left: 10px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.textNotes {
  color: black;
  background-color: #17a2b89e;
  margin-top: 4px;
  padding-left: 10px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.fileDetails {
  color: black;
  background-color: #17a2b8d9;
  margin-top: 4px;
  padding-left: 10px;
  padding-top: 5px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.fileSelect {
  border: none;
  color: white;
  padding: 7px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 16px;
  background-color: #17a2b8;
}

.btnAll {
  background-color: #17a2b8;
  color: white;
}

.textFileName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50px;
  border: none;
}

#auditTable {
  width: 100%;
}

#auditTable th {
  font-size: medium;
  padding-left: 10px;
  font-weight: bold;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

#auditTable td {
  padding-left: 10px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

///@at-root

.example-container {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0;
  right: 0;
}

.example-sidenav {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  background-color: #304065;
  color: gray;
}

.example-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.example-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.mat-list-item.active {
  color: #fff !important;
  //background-color: #17a2b8;
}

// .mat-list-item-content
// {
//   color: #fff;
// }
.mat-nav-list .mat-list-item {
  color: gray;
}

.mat-drawer-container {
  height: 100% !important;
}

.mytooltipclass .tooltip-inner {
  background-color: #17a2b8;
  width: 400px;
  max-width: 200pc;
  text-align: left;
  opacity: 1;
}

//w700 job
.myCustomModalClassProperties .modal-dialog {
  max-width: 80%;
  max-height: 80%;
  height: 80%;
}

.mytooltipclass700 .tooltip-inner {
  width: 200px;
  max-width: 200pc;
  text-align: left;
  opacity: 1;
}

.toast-error {
  background-color: rgba(255, 111, 105) !important;
}

.inputFieldruledesc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 1050px;
  border: none;
}

// .mat-expansion-panel:hover,
// .mat-expansion-panel:focus{
//   background-color:#17a2b8;
// color: white;
// }

.ftpfiletemplate {
  padding-left: 15px;
  width: 400px;
}

.anchorlink {
  color: #0d6efd;
  text-decoration: underline;
}

.maticoncolor {
  color: #17a2b8;
}

.mat-tree-node {
  display: flex;
  align-items: center;
  min-height: 0px !important;
  // height: 25px !important;
  flex: 1;
  overflow: hidden;
  word-wrap: break-word;
}

.mattreenodecustome {
  height: 35px !important;
}

.myCustomModalClassFTP .modal-dialog {
  max-width: 98%;
  max-height: 80%;
  height: 80%;
}
.email .modal-dialog {
  max-width: 98% !important;
  max-height: 80% !important;
  height: 80% !important;
}
.myCustomModalClassVB .modal-dialog {
  max-width: 80%;
  max-height: 60%;
  height: 60%;
}

.ng2-pdf-viewer-container {
  width: 98% !important;
}

.mat-card {
  font-size: 13px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.lblcolor {
  color: grey;
}

::ng-deep .my_mapClass .modal-dialog {
  max-width: 97%;
  width: 97%;
}

::ng-deep.cdk-overlay-container {
  z-index: 1000 !important;
}

::ng-deep.cdk-overlay-container,
.cdk-global-overlay-wrapper,
.cdk-overlay-connected-position-bounding-box {
  z-index: 9000 !important;
}

html.cdk-global-scrollblock {
  position: initial !important;
}

.tb_success {
  height: 46px;
  border: 1px solid #0080007d;
  /* box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px; */
  margin: 2px;
  background-color: #7dfb9340;
  color: #0d6f39;
  border-radius: 5px;
  cursor: pointer;
}

.tb_progress {
  // width: 100px;
  height: 46px;
  margin: 2px;
  border: 1px solid #989024;
  background-color: #fdf26659;
  color: #716909;
  border-radius: 5px;
  cursor: pointer;
}

.tb_failure {
  height: 46px;
  margin: 2px;
  border: 1px solid #6f0f0f9c;
  background-color: #ff80804d;
  color: #541111;
  border-radius: 5px;
  cursor: pointer;
}

.condition_modal .modal-dialog {
  max-width: 50%;
  max-height: 60%;
  height: 60%;
}

#UploadBtn {
  margin-left: 70px;
  position: relative;
}

.white-overlay {
  background-color: rgba(255, 255, 255, 1) !important;
}

.btn_style {
  background-color: $btn-color;
  font-size: $fontsize;
  color: $btntextcolor;
  height: $btnHeight;
  cursor: pointer;
}

.autocomplete-container {
  box-shadow: 0 2px 5px rgb(0 0 0 / 25%) !important;
  position: relative !important;
  overflow: visible !important;
  height: 30px !important;
  border-radius: 5px !important;
}

.autocomplete-container .input-container input {
  font-size: 11px !important;
  box-sizing: border-box !important;
  border: none !important;
  box-shadow: none !important;
  outline: 0 !important;
  background-color: #fff !important;
  color: rgba(0, 0, 0, 0.87) !important;
  width: 100% !important;
  padding: 0 15px !important;
  line-height: 30px !important;
  height: 30px !important;
  border-radius: 5px !important;
  padding-left: 5px !important;
  position: relative;
  padding-right: 10% !important;
}

.autocomplete-container .input-container input ul li a {
  padding: 14px 15px !important;
  display: block !important;
  text-decoration: none !important;
  cursor: pointer !important;
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: 13px !important;
}

.autocomplete-container .suggestions-container ul li a {
  padding: 14px 15px !important;
  display: block !important ;
  text-decoration: none !important;
  cursor: pointer !important;
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: 13px !important;
}

.autocomplete-container .input-container .x i {
  font-weight: 600 !important;
  color: rgba(0, 0, 0, 0.54) !important;
  font-size: 16px !important;
  vertical-align: middle !important;
  padding: 0 !important;
  position: relative !important;
  left: 7px !important;
}

.autocomplete-container .suggestions-container.is-visible {
  z-index: 999 !important;
  border-radius: 5px !important;
}

.cursor_pointer {
  cursor: pointer !important;
}

.mat-drawer-content {
  position: relative;
  z-index: 1;
  display: block;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  margin-left: 3.5% !important;
}

textarea {
  resize: none !important;
}
.matcardheight {
  height: 100%;
  overflow: overlay;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #17a2b8;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  color: #17a2b8;
  background-color: #17a2b8;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: rgb(255, 37, 37, 0.26);
}

.selected-item.ng-star-inserted a {
  padding-left: 10px !important;
  color: #eac334 !important;
}

// .mat-raised-button.mat-primary{
//   background-color:$primary;
// }
// .mat-flat-button.mat-primary{
//   background-color:$primary;
// }
// html, body {
//   max-width: 100%;
//   overflow-x: hidden;
//    height:auto!important;
// }
// div::-webkit-scrollbar {
//   width: 8px;
//   overflow:auto;
// }
// body::-webkit-scrollbar {
//   width: 8px;
//   overflow:auto;
// }

// div::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
// }
// body::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
// }
.bg-primary {
  background-color: $primary;
}
.btn-primary {
  background-color: $primary;
}

div::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 5px;
}
body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 5px;
}

.card {
  position: relative;
  display: block;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgb(0 0 0 / 8%);
  border-radius: 7px;
  box-shadow: rgb(99 99 99 / 20%) 1px 1px 5px 0px;
}

.card:hover {
  position: relative;
  display: block;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgb(0 0 0 / 8%);
  border-radius: 7px;
  box-shadow: rgb(99 99 99 / 20%) 1px 1px 5px 2px;
}

.active_button {
  border-radius: 8px;
  background-color: #111195;
  height: 32px;
  width: 33px;
  line-height: 31px;
  text-align: center;
  font-weight: 700;
  color: white;
}

.modal-footer {
  padding-block: 5px !important;
  // justify-content: space-around !important;
}
.modal-header {
  @include modalheaderTtext;
  padding-block: 5px !important;
  // justify-content: space-around !important;
}

.addconfigbtn {
  background-color: $btn-color;
  font-size: $fontsize;
  color: white;
  height: $btnHeight;
  line-height: 20px;
  right: 1%;
  top: 0%;
}
.fa-calendar {
  color: #ffb100;
}


.dangerborder{
  border-left: 4px solid red;
  margin-left: 2px;
  margin-top: 5px;
}
.successborder{
  border-left: 4px solid green;
  margin-left: 2px;
  margin-top: 5px;
}