a,
a:focus,
a:hover,
button,
pre {
  color: inherit
}

.btn.btn-default,
.btn.btn-secondary,
.btn.white,
.card,
blockquote,
pre {
  border-color: rgba(120, 130, 140, .13)
}

.navbar-brand:hover,
.pagination>li>a,
a,
a:focus,
a:hover {
  text-decoration: none
}

.h-1x,
.list-icon div,
.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap
}

.avatar,
.h-1x,
.label,
.list-icon div,
.md-btn,
.no-wrap,
.text-ellipsis {
  white-space: nowrap
}

.md-btn,
.text-u-c {
  text-transform: uppercase
}

html {
  background-color: #e0e0e0
}

body {
  color: rgba(0, 0, 0, .87);
  font-size: .875rem;
  background-color: #f0f0f0;
  -webkit-font-smoothing: antialiased
}

:focus {
  outline: 0 !important
}

a {
  cursor: pointer;
  outline: 0
}

pre {
  background-color: #f8f8f8
}

small {
  font-size: 90%
}

sup {
  position: relative;
  top: -1em;
  font-size: 75%
}

.pull-center {
  position: absolute;
  left: 50%
}

.close {
  font-size: 1.3rem
}

.img-responsive {
  width: 100%;
  height: auto
}

.text-center {
  text-align: center
}

.text-left {
  text-align: left
}

.text-right {
  text-align: right
}

.btn-icon,
.label,
.nav-icon,
.pager {
  text-align: center
}

.pull-left {
  float: left
}

.pull-right {
  float: right
}

.center-block {
  margin: 0 auto
}

.img-circle {
  border-radius: 100%
}

.collapse.in,
.fade.in,
.tooltip.in {
  display: block;
  opacity: 1
}

.label,
.label.up {
  display: inline-block
}

@media (max-width:767px) {
  .pull-none-sm {
    float: none !important
  }
}

.label {
  padding: .25em .5em;
  font-size: 75%;
  line-height: 1;
  vertical-align: baseline;
  font-weight: 700;
  background-color: #b8b8b8;
  color: #fff;
  border-radius: .2rem
}

.btn-outline,
.label.no-bg {
  background-color: transparent
}

.label.no-bg {
  color: inherit
}

.label.up {
  position: absolute;
  top: 5px;
  right: 5px;
  min-width: 4px;
  min-height: 4px
}

.label-lg {
  font-size: 1.33rem;
  padding: 5px 9px
}

.label-sm {
  padding: .15em .35em
}

.label-xs {
  padding: .1em .25em
}

.list-group-item.active>.label,
.nav-pills>.active>a>.label {
  color: rgba(0, 0, 0, .87)
}

.btn .label {
  position: relative;
  top: -1px
}

.btn {
  font-weight: 500;
  outline: 0 !important
}

.avatar,
.navbar-brand {
  font-weight: 700
}

.md-btn,
.md-btn:focus {
  outline: 0
}

.btn:not([disabled]).active,
.btn:not([disabled]):focus,
.btn:not([disabled]):hover {
  box-shadow: inset 0 -10rem 0 rgba(158, 158, 158, .2)
}

.btn>i.pull-left,
.btn>i.pull-right {
  line-height: 1.5
}

.btn.rounded {
  padding-left: 1.2em;
  padding-right: 1.2em
}

.btn-md {
  padding: .4695rem .75rem;
  font-size: .9rem
}

.btn-xs {
  padding: .2195rem .5rem;
  font-size: .8rem
}

.btn-outline {
  border-width: 1px;
  box-shadow: none !important
}

.btn.b-primary:focus,
.btn.b-primary:hover,
.btn.primary:focus,
.btn.primary:hover {
  color: rgba(255, 255, 255, .87) !important;
  background-color: #0cc2aa
}

.btn.accent:focus,
.btn.accent:hover,
.btn.b-accent:focus,
.btn.b-accent:hover {
  color: rgba(255, 255, 255, .87) !important;
  background-color: #a88add
}

.btn.b-warn:focus,
.btn.b-warn:hover,
.btn.warn:focus,
.btn.warn:hover {
  color: rgba(255, 255, 255, .87) !important;
  background-color: #fcc100
}

.btn.b-success:focus,
.btn.b-success:hover,
.btn.success:focus,
.btn.success:hover {
  color: rgba(255, 255, 255, .87) !important;
  background-color: #6cc788
}

.btn.b-info:focus,
.btn.b-info:hover,
.btn.info:focus,
.btn.info:hover {
  color: rgba(255, 255, 255, .87) !important;
  background-color: #6887ff
}

.btn.b-warning:focus,
.btn.b-warning:hover,
.btn.warning:focus,
.btn.warning:hover {
  color: rgba(255, 255, 255, .87) !important;
  background-color: #f77a99
}

.btn.b-danger:focus,
.btn.b-danger:hover,
.btn.danger:focus,
.btn.danger:hover {
  color: rgba(255, 255, 255, .87) !important;
  background-color: #f44455
}

.btn.b-dark:focus,
.btn.b-dark:hover,
.btn.dark:focus,
.btn.dark:hover {
  color: rgba(255, 255, 255, .87) !important;
  background-color: #2e3e4e
}

.btn.b-black:focus,
.btn.b-black:hover,
.btn.black:focus,
.btn.black:hover {
  color: rgba(255, 255, 255, .87) !important;
  background-color: #2a2b3c
}

.btn.b-white:focus,
.btn.b-white:hover {
  color: rgba(0, 0, 0, .87) !important;
  background-color: #fff
}

.btn.b-light:focus,
.btn.b-light:hover {
  color: rgba(0, 0, 0, .87) !important;
  background-color: #f8f8f8
}

.btn-icon {
  padding: 0 !important;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  position: relative;
  overflow: hidden
}

.btn-icon i {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  line-height: inherit;
  border-radius: inherit;
  -webkit-transition: all .3s;
  transition: all .3s
}

.btn-icon.btn-xs {
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem
}

.btn-icon.btn-sm {
  width: 1.775rem;
  height: 1.775rem;
  line-height: 1.775rem
}

.btn-icon.btn-lg {
  width: 3.167rem;
  height: 3.167rem;
  line-height: 3.167rem
}

.btn-icon.btn-social i:last-child {
  top: 100%
}

.btn-icon.btn-social.active i:first-child,
.btn-icon.btn-social:focus i:first-child,
.btn-icon.btn-social:hover i:first-child {
  top: -100%
}

.btn-icon.btn-social.active i:last-child,
.btn-icon.btn-social:focus i:last-child,
.btn-icon.btn-social:hover i:last-child {
  top: 0;
  color: #fff
}

.btn-icon.btn-social-colored i:first-child {
  color: #fff
}

.btn-default {
  background-color: #fff;
  color: rgba(0, 0, 0, .87)
}

.btn-groups .btn {
  margin-bottom: .25rem
}

.btn-fw {
  min-width: 7rem
}

.carousel-control .glyphicon {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  display: inline-block;
  margin-top: -10px;
  margin-left: -10px
}

.dropdown-menu {
  font-size: .875rem;
  color: rgba(0, 0, 0, .87);
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, .1);
  -webkit-transform-origin: top left;
  -moz-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left
}

.dropdown-menu>li:not(.dropdown-item)>a {
  display: block;
  padding: 3px 20px
}

.dropdown-menu>li:not(.dropdown-item)>a:focus,
.dropdown-menu>li:not(.dropdown-item)>a:hover {
  background-color: rgba(0, 0, 0, .065)
}

.dropdown-menu>.divider {
  height: 1px;
  margin: .5rem 0;
  overflow: hidden;
  background-color: rgba(120, 130, 140, .13)
}

.modal-open-aside,
.modal.inactive {
  overflow: visible
}

.dropdown-menu .active {
  background-color: rgba(0, 0, 0, .065)
}

.dropdown-menu.pull-left {
  left: auto !important;
  right: 100%
}

.dropdown-menu.pull-right {
  left: auto;
  right: 0;
  -webkit-transform-origin: top right;
  -moz-transform-origin: top right;
  -ms-transform-origin: top right;
  transform-origin: top right
}

.dropdown-menu.pull-up {
  top: 0
}

.dropdown-menu.pull-down {
  top: 100% !important;
  left: 0 !important
}

.dropup .dropdown-menu {
  -webkit-transform-origin: bottom left;
  -moz-transform-origin: bottom left;
  -ms-transform-origin: bottom left;
  transform-origin: bottom left
}

.dropup .dropdown-menu.pull-right {
  -webkit-transform-origin: bottom right;
  -moz-transform-origin: bottom right;
  -ms-transform-origin: bottom right;
  transform-origin: bottom right
}

.dropdown-menu.datepicker,
.dropdown-menu.timepicker {
  padding: 6px;
  font-size: .8rem
}

.dropdown-menu.datepicker .btn,
.dropdown-menu.timepicker .btn {
  font-size: .8rem
}

.dropdown-menu.datepicker .btn.btn-primary,
.dropdown-menu.timepicker .btn.btn-primary {
  background-color: #6887ff;
  color: #fff
}

.dropdown-menu.datepicker .btn:not(:hover),
.dropdown-menu.timepicker .btn:not(:hover) {
  box-shadow: 0 0 1px transparent
}

.dropdown-item {
  color: inherit
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: inherit;
  background-color: rgba(0, 0, 0, .065)
}

.dropdown-divider {
  background-color: rgba(120, 130, 140, .13)
}

.flex-row>.nav-item>.dropdown-menu {
  position: absolute !important
}

.dropdown-menu-scale {
  display: block;
  opacity: 0;
  -webkit-transform: scale(.8);
  -ms-transform: scale(.8);
  transform: scale(.8);
  -webkit-transition: all 250ms cubic-bezier(.24, .22, .015, 1.56);
  transition: all 250ms cubic-bezier(.24, .22, .015, 1.56);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  pointer-events: none
}

.open>.dropdown-menu {
  display: block
}

.show>.dropdown-menu-scale {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  pointer-events: auto;
  display: block !important
}

.dropdown-header {
  padding: 8px 16px
}

.dropdown-submenu {
  position: relative
}

.dropdown-submenu:focus>.dropdown-menu,
.dropdown-submenu:hover>.dropdown-menu {
  display: block
}

.dropdown-submenu .dropdown-menu {
  left: 100%;
  top: 0;
  margin-top: -6px;
  margin-left: -1px
}

.dropup .dropdown-submenu>.dropdown-menu {
  top: auto;
  bottom: 0;
  margin-bottom: -6px
}

.popover {
  color: rgba(0, 0, 0, .87)
}

@media (max-width:575px) {
  .pull-none-xs {
    float: none !important
  }

  .dropdown-menu.pull-none-xs {
    left: 0
  }
}

.form-control {
  border-color: rgba(120, 130, 140, .2);
  border-radius: 0
}

.form-control:focus {
  border-color: rgba(120, 130, 140, .3)
}

.form-control-label {
  padding-top: .5rem;
  padding-bottom: .5rem;
  margin-bottom: 0
}

.custom-select {
  border-color: rgba(120, 130, 140, .13)
}

.form-control::-webkit-input-placeholder {
  color: inherit;
  opacity: .3
}

.form-control::-moz-placeholder {
  color: inherit;
  opacity: .3
}

.form-control:-ms-input-placeholder {
  color: inherit;
  opacity: .3
}

.form-control-spin {
  position: absolute;
  z-index: 2;
  right: 10px;
  top: 50%;
  margin-top: -7px
}

.input-group-addon {
  border-color: rgba(120, 130, 140, .2) !important;
  background-color: transparent;
  color: inherit
}

.form-group-stack .form-control {
  margin-top: -1px;
  position: relative;
  border-radius: 0
}

.form-group-stack .form-control:active,
.form-group-stack .form-control:focus {
  z-index: 1
}

.form-validation .form-control.ng-dirty.ng-invalid {
  border-color: #f44455
}

.form-validation .form-control.ng-dirty.ng-valid,
.form-validation .form-control.ng-dirty.ng-valid:focus {
  border-color: #6cc788
}

.form-validation .ui-checks .ng-invalid.ng-dirty+i {
  border-color: #f44455
}

.form-file {
  position: relative
}

.form-file input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0
}

.input-group-sm>.form-control {
  min-height: 1.95rem
}

.active-checked {
  opacity: 0
}

input:checked+.active-checked {
  opacity: 1
}

.list-group {
  border-radius: 3px;
  background: #fff
}

.list-group.no-radius .list-group-item {
  border-radius: 0 !important
}

.list-group.no-borders .list-group-item {
  border: none
}

.list-group.no-border .list-group-item {
  border-width: 1px 0
}

.list-group.no-border .list-group-item:first-child {
  border-top-width: 0
}

.list-group.no-border .list-group-item:last-child {
  border-bottom-width: 0
}

.list-group.no-bg .list-group-item {
  background-color: transparent
}

.list-group.list-group-gap {
  background: 0 0
}

.list-group-item {
  border-color: rgba(120, 130, 140, .065);
  background: 0 0;
  padding: 12px 16px;
  background-clip: padding-box
}

.list-group-item.media {
  margin-top: 0
}

.list-group-item.active,
.list-group-item.active:focus,
.list-group-item.active:hover {
  color: inherit;
  background-color: rgba(0, 0, 0, .065);
  border-color: rgba(120, 130, 140, .065);
  border-bottom-color: rgba(120, 130, 140, .065)
}

.list-group-item.active a,
.list-group-item.active:focus a,
.list-group-item.active:hover a {
  color: inherit
}

.list-group-item:first-child {
  border-top-color: rgba(120, 130, 140, .13)
}

.list-group-item:last-child {
  border-bottom-color: rgba(120, 130, 140, .13)
}

.list-group-alt .list-group-item.active:nth-child(2n+2),
.list-group-alt .list-group-item:nth-child(2n+2) {
  background-color: rgba(0, 0, 0, .065)
}

.list-group-lg .list-group-item {
  padding: 16px 24px
}

.list-group-md .list-group-item {
  padding-top: 16px;
  padding-bottom: 16px
}

.list-group-sm .list-group-item {
  padding: 10px 12px
}

.list-group-gap .list-group-item {
  margin-bottom: 5px;
  border-radius: 3px
}

.list-group-gap .list-group-item:first-child {
  border-top-color: rgba(120, 130, 140, .065)
}

.list-group-gap .list-group-item:last-child {
  border-bottom-color: rgba(120, 130, 140, .065)
}

a.list-group-item {
  color: inherit
}

a.list-group-item.hover,
a.list-group-item:focus,
a.list-group-item:hover {
  color: inherit;
  background-color: rgba(0, 0, 0, .065)
}

.aside,
.dark-white,
.modal-content {
  color: rgba(0, 0, 0, .87)
}

.modal .left {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  -webkit-transition: -webkit-transform .4s cubic-bezier(.25, .8, .25, 1);
  -moz-transition: -moz-transform .4s cubic-bezier(.25, .8, .25, 1);
  -o-transition: -o-transform .4s cubic-bezier(.25, .8, .25, 1);
  transition: transform .4s cubic-bezier(.25, .8, .25, 1)
}

.modal .right,
.modal .top {
  -webkit-transition: -webkit-transform .4s cubic-bezier(.25, .8, .25, 1);
  -moz-transition: -moz-transform .4s cubic-bezier(.25, .8, .25, 1);
  -o-transition: -o-transform .4s cubic-bezier(.25, .8, .25, 1)
}

.modal .right {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  transition: transform .4s cubic-bezier(.25, .8, .25, 1)
}

.modal .top {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
  transition: transform .4s cubic-bezier(.25, .8, .25, 1)
}

.modal .bottom {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  -webkit-transition: -webkit-transform .4s cubic-bezier(.25, .8, .25, 1);
  -moz-transition: -moz-transform .4s cubic-bezier(.25, .8, .25, 1);
  -o-transition: -o-transform .4s cubic-bezier(.25, .8, .25, 1);
  transition: transform .4s cubic-bezier(.25, .8, .25, 1)
}

.modal.in .bottom,
.modal.in .left,
.modal.in .right,
.modal.in .top,
.modal.show .bottom,
.modal.show .left,
.modal.show .right,
.modal.show .top {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.modal.inactive {
  bottom: auto
}

.modal>.left,
.modal>.right {
  position: fixed;
  z-index: 1050;
  bottom: 0;
  top: 0
}

.modal>.left {
  left: 0;
  right: auto
}

.modal>.right {
  left: auto;
  right: 0
}

.modal>.bottom,
.modal>.top {
  position: fixed;
  z-index: 1050;
  left: 0;
  right: 0
}

.modal>.top {
  top: 0;
  bottom: auto
}

.modal>.bottom {
  top: auto;
  bottom: 0
}

.nav-link,
.nav-tabs {
  position: relative
}

.modal-content,
.modal-footer,
.modal-header {
  border-color: rgba(120, 130, 140, .13)
}

.aside-header,
.aside-header .close {
  color: inherit !important;
  background-color: transparent !important
}

.aside-footer,
.aside-header {
  border-color: rgba(120, 130, 140, .13) !important
}

.nav,
.nav-item,
.nav-link {
  border: inherit
}

.aside-title {
  font-size: 1.2rem;
  margin: .45rem 0
}

.modal.animate .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0)
}

.modal.animate .fade-right-big {
  -webkit-animation: fadeOutLeftBig .5s;
  animation: fadeOutLeftBig .5s
}

.modal.animate .fade-left-big {
  -webkit-animation: fadeOutRightBig .5s;
  animation: fadeOutRightBig .5s
}

.modal.animate .fade-up-big {
  -webkit-animation: fadeOutUpBig .5s;
  animation: fadeOutUpBig .5s
}

.modal.animate .fade-down-big {
  -webkit-animation: fadeOutDownBig .5s;
  animation: fadeOutDownBig .5s
}

.modal.animate .fade-right {
  -webkit-animation: fadeOutLeft .5s;
  animation: fadeOutLeft .5s
}

.modal.animate .fade-left {
  -webkit-animation: fadeOutRight .5s;
  animation: fadeOutRight .5s
}

.modal.animate .fade-up {
  -webkit-animation: fadeOutUp .5s;
  animation: fadeOutUp .5s
}

.modal.animate .fade-down {
  -webkit-animation: fadeOutDown .5s;
  animation: fadeOutDown .5s
}

.modal.animate .flip-x {
  -webkit-animation: flipOutX .5s;
  animation: flipOutX .5s
}

.modal.animate .flip-y {
  -webkit-animation: flipOutY .5s;
  animation: flipOutY .5s
}

.modal.animate .zoom {
  -webkit-animation: zoomOut .5s;
  animation: zoomOut .5s
}

.modal.animate .roll {
  -webkit-animation: rollOut .5s;
  animation: rollOut .5s
}

.modal.animate .bounce {
  -webkit-animation: bounceOut .5s;
  animation: bounceOut .5s
}

.modal.animate .rotate {
  -webkit-animation: rotateOut .5s;
  animation: rotateOut .5s
}

.modal.animate.in .fade-right-big,
.modal.animate.show .fade-right-big {
  -webkit-animation: fadeInRightBig .5s;
  animation: fadeInRightBig .5s
}

.modal.animate.in .fade-left-big,
.modal.animate.show .fade-left-big {
  -webkit-animation: fadeInLeftBig .5s;
  animation: fadeInLeftBig .5s
}

.modal.animate.in .fade-up-big,
.modal.animate.show .fade-up-big {
  -webkit-animation: fadeInUpBig .5s;
  animation: fadeInUpBig .5s
}

.modal.animate.in .fade-down-big,
.modal.animate.show .fade-down-big {
  -webkit-animation: fadeInDownBig .5s;
  animation: fadeInDownBig .5s
}

.modal.animate.in .fade-right,
.modal.animate.show .fade-right {
  -webkit-animation: fadeInRight .5s;
  animation: fadeInRight .5s
}

.modal.animate.in .fade-left,
.modal.animate.show .fade-left {
  -webkit-animation: fadeInLeft .5s;
  animation: fadeInLeft .5s
}

.modal.animate.in .fade-up,
.modal.animate.show .fade-up {
  -webkit-animation: fadeInUp .5s;
  animation: fadeInUp .5s
}

.modal.animate.in .fade-down,
.modal.animate.show .fade-down {
  -webkit-animation: fadeInDown .5s;
  animation: fadeInDown .5s
}

.modal.animate.in .flip-x,
.modal.animate.show .flip-x {
  -webkit-animation: flipInX .5s;
  animation: flipInX .5s
}

.modal.animate.in .flip-y,
.modal.animate.show .flip-y {
  -webkit-animation: flipInY .5s;
  animation: flipInY .5s
}

.modal.animate.in .zoom,
.modal.animate.show .zoom {
  -webkit-animation: zoomIn .5s;
  animation: zoomIn .5s
}

.modal.animate.in .roll,
.modal.animate.show .roll {
  -webkit-animation: rollIn .5s;
  animation: rollIn .5s
}

.modal.animate.in .bounce,
.modal.animate.show .bounce {
  -webkit-animation: bounceIn .5s;
  animation: bounceIn .5s
}

.modal.animate.in .rotate,
.modal.animate.show .rotate {
  -webkit-animation: rotateIn .5s;
  animation: rotateIn .5s
}

.flex-row .nav-link {
  padding-left: .5rem;
  padding-right: .5rem
}

.nav-md .nav-link {
  padding: .5rem 1rem
}

.nav-sm .nav-link {
  padding: .25rem .75rem
}

.nav-xs .nav-link {
  padding: .15rem .5rem;
  font-size: 90%
}

.nav-rounded .nav-link {
  border-radius: 2rem
}

.nav-tabs {
  border-bottom-width: 0;
  z-index: 1
}

.nav-tabs .nav-link {
  background: 0 0 !important;
  color: inherit !important
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-link.active:focus,
.nav-tabs .nav-link.active:hover,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: rgba(120, 130, 140, .13);
  border-bottom-color: transparent
}

.tab-content.tab-alt .tab-pane {
  display: block;
  height: 0;
  overflow: hidden
}

.tab-content.tab-alt .tab-pane.active {
  height: auto;
  overflow: visible
}

.nav-justified .nav-item {
  display: table-cell;
  width: 1%;
  float: none !important;
  text-align: center
}

.nav-lists .nav-item {
  border-bottom: 1px solid rgba(120, 130, 140, .13);
  background-clip: padding-box
}

.nav-active-border .nav-link:before {
  content: '';
  position: absolute;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  left: 50%;
  right: 50%;
  bottom: 0;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-bottom-color: transparent
}

.nav-active-border .nav-link.active:before,
.nav-active-border .nav-link:focus:before,
.nav-active-border .nav-link:hover:before {
  left: 0;
  right: 0;
  border-bottom-color: inherit
}

.nav-active-border.top .nav-link:before {
  bottom: auto;
  top: 0
}

.nav-active-border.left .nav-link {
  float: none
}

.nav-active-border.left .nav-link:before {
  border-bottom-width: 0;
  right: auto;
  border-left-width: 3px;
  border-left-style: solid;
  border-left-color: transparent;
  left: 0;
  top: 50%;
  bottom: 50%
}

.nav-active-border.left .nav-link.active:before,
.nav-active-border.left .nav-link:focus:before,
.nav-active-border.left .nav-link:hover:before {
  top: 0;
  bottom: 0;
  border-left-color: inherit
}

.nav-active-border.left.right .nav-link:before {
  left: auto;
  right: 0
}

.breadcrumb {
  background-color: rgba(0, 0, 0, .065)
}

.breadcrumb>li+li:before {
  content: '/ '
}

.app-content::after,
.arrow:after,
.arrow:before,
.list-body:after,
.list-item::after,
.navside .nav li a::after,
.pager::after,
.ui-check>i:before {
  content: ""
}

.navbar {
  background: inherit;
  min-height: 3.5rem
}

.navbar .up {
  margin: 0 -.6em
}

.navbar .avatar {
  top: -2px
}

.navbar-md {
  min-height: 4rem;
  padding-top: .75rem;
  padding-bottom: .75rem
}

.navbar-md .navbar {
  min-height: auto;
  padding-top: 0;
  padding-bottom: 0
}

.navbar-sm {
  min-height: 3rem;
  padding-top: .25rem;
  padding-bottom: .25rem
}

.navbar-sm .navbar {
  min-height: auto;
  padding-top: 0;
  padding-bottom: 0
}

.navbar-brand img,
.navbar-brand svg {
  max-height: 24px;
  vertical-align: -4px;
  display: inline-block
}

.navbar-brand>span {
  display: inline-block;
  line-height: .8;
  margin-left: 10px
}

.navbar-brand.md img,
.navbar-brand.md svg {
  max-height: 32px;
  vertical-align: -8px
}

.navbar-brand.lg img,
.navbar-brand.lg svg {
  max-height: 48px;
  vertical-align: -16px
}

.pull-center .navbar-brand {
  margin-left: -50%;
  float: left
}

.navbar-collapse.collapse.show,
.navbar-collapse.collapsing {
  background: inherit;
  left: 0;
  top: 100%;
  max-height: 100vh;
  overflow: auto;
  width: 100%
}

.navbar-collapse.collapse.show .nav-active-border,
.navbar-collapse.collapsing .nav-active-border {
  border-color: transparent
}

@media (max-width:575px) {

  .navbar-toggleable .navbar-collapse.collapse.show,
  .navbar-toggleable .navbar-collapse.collapsing {
    padding: 0 1rem 4rem;
    position: absolute;
    z-index: 1040
  }
}

@media (max-width:767px) {

  .navbar-toggleable-sm .navbar-collapse.collapse.show,
  .navbar-toggleable-sm .navbar-collapse.collapsing {
    padding: 0 1rem 4rem;
    position: absolute;
    z-index: 1040
  }
}

@media (max-width:991px) {

  .navbar-toggleable-md .navbar-collapse.collapse.show,
  .navbar-toggleable-md .navbar-collapse.collapsing {
    padding: 0 1rem 4rem;
    position: absolute;
    z-index: 1040
  }
}

@media (max-width:1199px) {

  .navbar-toggleable-lg .navbar-collapse.collapse.show,
  .navbar-toggleable-lg .navbar-collapse.collapsing {
    padding: 0 1rem 4rem;
    position: absolute;
    z-index: 1040
  }
}

.navbar-toggleable-xl .navbar-collapse.collapse.show,
.navbar-toggleable-xl .navbar-collapse.collapsing {
  padding: 0 1rem 4rem;
  position: absolute;
  z-index: 1040
}

.pager .page-item,
.pager>li>a,
.pager>li>span,
.pagination .page-item,
.pagination>li>a,
.pagination>li>span {
  color: inherit !important;
  background-color: transparent !important;
  border-color: rgba(120, 130, 140, .13) !important
}

.pager .page-item:focus,
.pager .page-item:hover,
.pager>li>a:focus,
.pager>li>a:hover,
.pager>li>span:focus,
.pager>li>span:hover,
.pagination .page-item:focus,
.pagination .page-item:hover,
.pagination>li>a:focus,
.pagination>li>a:hover,
.pagination>li>span:focus,
.pagination>li>span:hover {
  border-color: rgba(120, 130, 140, .13);
  background-color: rgba(0, 0, 0, .065);
  color: inherit
}

.pager .page-item.active,
.pager>.active>a,
.pager>.active>span,
.pagination .page-item.active,
.pagination>.active>a,
.pagination>.active>span {
  color: #fff !important;
  background-color: #0cc2aa !important;
  border-color: #0cc2aa !important
}

.pagination>li {
  display: inline;
  list-style: none
}

.pagination>li>a {
  position: relative;
  float: left;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.5;
  color: #0275d8;
  background-color: #fff;
  border: 1px solid #ddd
}

.pagination>li>a:focus,
.pagination>li>a:hover {
  color: #014c8c;
  background-color: #eceeef;
  border-color: #ddd
}

.pager {
  padding-left: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  list-style: none
}

.pager::after {
  display: block;
  clear: both
}

.pager li {
  display: inline
}

.pager li>a,
.pager li>span {
  display: inline-block;
  padding: 5px 14px;
  background-color: rgba(0, 0, 0, .065);
  border: 1px solid rgba(120, 130, 140, .13);
  border-radius: 500px
}

.pager li>a:focus,
.pager li>a:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, .065)
}

.pager .disabled>a,
.pager .disabled>a:focus,
.pager .disabled>a:hover,
.pager .disabled>span {
  cursor: inherit;
  background-color: rgba(0, 0, 0, .065)
}

.md-btn,
.pointer,
.ui-check,
.ui-switch {
  cursor: pointer
}

.pager-next>a,
.pager-next>span {
  float: right
}

.pager-prev>a,
.pager-prev>span {
  float: left
}

.progress {
  border-radius: .25rem;
  overflow: hidden;
  background-color: rgba(0, 0, 0, .065)
}

.avatar,
.avatar img {
  border-radius: 500px
}

.progress-xxs {
  height: 4px
}

.progress-xs {
  height: 8px
}

.progress-sm {
  height: 12px;
  font-size: .8em
}

.col-0 {
  clear: left
}

.row.no-gutter {
  margin-left: 0;
  margin-right: 0
}

.row.no-gutter [class*=col-] {
  padding-left: 0;
  padding-right: 0
}

.row {
  margin-left: -12px;
  margin-right: -12px
}

.row [class*=col-] {
  padding-left: 12px;
  padding-right: 12px
}

.row-sm {
  margin-left: -8px;
  margin-right: -8px
}

.row-sm [class*=col-] {
  padding-left: 8px;
  padding-right: 8px
}

.row-xs {
  margin-left: -4px;
  margin-right: -4px
}

.row-xs [class*=col-] {
  padding-left: 4px;
  padding-right: 4px
}

@media (max-width:991px) {
  .row {
    margin-left: -8px;
    margin-right: -8px
  }

  .row [class*=col-] {
    padding-left: 8px;
    padding-right: 8px
  }

  .row-2 [class*=col] {
    width: 50%;
    float: left
  }

  .row-2 .col-0 {
    clear: none
  }

  .row-2 li:nth-child(odd) {
    clear: left;
    margin-left: 0
  }
}

@media (max-width:767px) {
  .row {
    margin-left: -4px;
    margin-right: -4px
  }

  .row [class*=col-] {
    padding-left: 4px;
    padding-right: 4px
  }
}

.panel .table {
  border-color: rgba(120, 130, 140, .045) !important
}

.table-bordered,
.table>thead>tr>th {
  border-color: rgba(120, 130, 140, .045)
}

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th {
  padding-left: 16px;
  padding-right: 16px;
  border-color: rgba(120, 130, 140, .045)
}

.table>thead>tr>th {
  padding: 10px 16px
}

.table-condensed tbody>tr>td,
.table-condensed tbody>tr>th,
.table-condensed tfoot>tr>td,
.table-condensed tfoot>tr>th,
.table-condensed thead>tr>td,
.table-condensed thead>tr>th {
  padding: 5px
}

.table-striped>tbody>tr:nth-child(odd) {
  background-color: rgba(0, 0, 0, .025);
  background-clip: padding-box
}

.table-striped>thead>th {
  background-color: rgba(0, 0, 0, .025);
  border-right: 1px solid rgba(120, 130, 140, .045)
}

.table-striped>thead>th:last-child {
  border-right: none
}

.arrow.bottom:after,
.arrow.right:after,
.arrow.top:after {
  border-right-color: transparent
}

.table td.active,
.table th.active,
.table tr.active,
.table-hover tr:hover {
  background-color: rgba(0, 0, 0, .065) !important
}

.arrow {
  position: absolute;
  z-index: 10
}

.arrow:after,
.arrow:before {
  position: absolute;
  left: 0;
  display: block;
  width: 0;
  height: 0;
  border-width: 9px;
  border-color: transparent;
  border-style: solid
}

.arrow:after {
  border-width: 8px
}

.arrow.top {
  top: -9px;
  left: 50%;
  margin-left: -9px
}

.arrow.top.pull-in {
  top: -4px
}

.arrow.top:before {
  border-bottom-color: rgba(120, 130, 140, .13);
  border-top-width: 0
}

.arrow.top:after {
  top: 1px;
  left: 1px;
  border-top-color: transparent;
  border-bottom-color: inherit;
  border-left-color: transparent;
  border-top-width: 0
}

.arrow.right {
  top: 50%;
  right: 0;
  margin-top: -9px
}

.arrow.right.pull-in {
  right: 4px
}

.arrow.right:before {
  border-left-color: rgba(120, 130, 140, .13);
  border-right-width: 0
}

.arrow.right:after {
  top: 1px;
  left: 0;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-left-color: inherit;
  border-right-width: 0
}

.arrow.bottom:after,
.arrow.left:after {
  border-bottom-color: transparent;
  border-left-color: transparent
}

.arrow.bottom {
  bottom: 0;
  left: 50%;
  margin-left: -9px
}

.arrow.bottom.pull-in {
  bottom: 4px
}

.arrow.bottom:before {
  border-top-color: rgba(120, 130, 140, .13);
  border-bottom-width: 0
}

.arrow.bottom:after {
  top: 0;
  left: 1px;
  border-top-color: inherit;
  border-bottom-width: 0
}

.arrow.left {
  top: 50%;
  left: -9px;
  margin-top: -9px
}

.arrow.left.pull-in {
  left: -4px
}

.arrow.left:before {
  border-right-color: rgba(120, 130, 140, .13);
  border-left-width: 0
}

.arrow.left:after {
  top: 1px;
  left: 1px;
  border-top-color: transparent;
  border-right-color: inherit;
  border-left-width: 0
}

.arrow.pull-left {
  left: 18px
}

.arrow.pull-right {
  left: auto;
  right: 26px
}

.arrow.pull-top {
  top: 18px
}

.arrow.pull-bottom {
  top: auto;
  bottom: 26px
}

.arrow.b-accent:before,
.arrow.b-danger:before,
.arrow.b-dark:before,
.arrow.b-info:before,
.arrow.b-primary:before,
.arrow.b-success:before,
.arrow.b-warning:before {
  border-color: transparent
}

.avatar {
  position: relative;
  display: inline-block;
  width: 40px;
  line-height: 1
}

.avatar img {
  width: 100%
}

.avatar i {
  position: absolute;
  left: 0;
  top: 0;
  width: 10px;
  height: 10px;
  margin: 1px;
  border-width: 2px;
  border-style: solid;
  border-radius: 100%
}

.avatar i.bottom,
.avatar i.right {
  left: auto;
  right: 0
}

.avatar i.bottom,
.avatar i.left {
  top: auto;
  bottom: 0
}

.avatar i.on {
  background-color: #6cc788
}

.avatar i.off {
  background-color: #f8f8f8
}

.avatar i.away {
  background-color: #f77a99
}

.avatar i.busy {
  background-color: #f44455
}

.avatar.w-32 i {
  margin: 0
}

.avatar.w-48 i {
  margin: 2px
}

.avatar.w-56 i {
  margin: 3px
}

.avatar.w-64 i {
  margin: 4px
}

.avatar.w-96 i {
  margin: 9px
}

.avatar.w-128 i {
  margin: 14px
}

.row-col {
  display: table;
  table-layout: fixed;
  border-spacing: 0;
  width: 100%;
  height: 100%
}

.row-col>[class*=col-],
.row-col>[class*=" col-"] {
  vertical-align: top;
  float: none;
  padding: 0;
  position: static
}

.box-body,
.box-footer,
.box-header {
  padding: 1rem
}

.row-row {
  display: table-row;
  height: 100%
}

.row-cell {
  display: table-cell;
  vertical-align: top
}

.row-body {
  position: relative;
  height: 100%;
  width: 100%
}

.ie .row-body {
  display: table-cell;
  overflow: auto
}

.ie .row-body .row-inner {
  overflow: visible !important
}

.row-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch
}

.v-m {
  vertical-align: middle !important
}

.v-t {
  vertical-align: top !important
}

.v-b {
  vertical-align: bottom !important
}

.easyPieChart canvas,
.navbar .nav-text,
.sl-icon>i,
.ui-check>i {
  vertical-align: middle
}

.v-c {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center
}

@media (min-width:992px) {

  .row-col>[class*=col-lg],
  .row-col>[class*=" col-lg"] {
    display: table-cell;
    height: 100%
  }
}

@media (min-width:768px) {

  .row-col>[class*=col-md],
  .row-col>[class*=" col-md"] {
    display: table-cell;
    height: 100%
  }
}

@media (min-width:576px) {

  .row-col>[class*=col-sm],
  .row-col>[class*=" col-sm"] {
    display: table-cell;
    height: 100%
  }
}

.row-col>[class*=col-xs],
.row-col>[class*=" col-xs"] {
  display: table-cell;
  height: 100%
}

@media (max-width:575px) {
  .row-col-xs {
    display: block
  }

  .row-col-xs .row-body {
    overflow: visible !important
  }

  .row-col-xs .row-inner {
    position: static
  }
}

.box,
.box-color {
  background-color: #fff;
  position: relative;
  margin-bottom: 1.5rem
}

.box-header {
  position: relative
}

.box-header h2,
.box-header h3,
.box-header h4 {
  margin: 0;
  font-size: 18px;
  line-height: 1
}

.box-header h3 {
  font-size: 16px
}

.box-header h4 {
  font-size: 15px
}

.box-header small {
  display: block;
  margin-top: 4px;
  opacity: .6
}

.box-divider {
  border-bottom: 1px solid rgba(120, 130, 140, .13);
  margin: 0 16px;
  height: 0
}

.ui-check>i,
.ui-check>span {
  margin-left: -20px
}

.box-tool {
  position: absolute;
  right: 10px;
  top: 10px
}

.box-tool .nav-link {
  padding: .25rem .35rem
}

.box-shadow,
.box-shadow .box,
.box-shadow .box-color {
  box-shadow: 0 2px 2px rgba(0, 0, 0, .03), 0 1px 0 rgba(0, 0, 0, .03)
}

.box-shadow-z0,
.box-shadow-z0 .box,
.box-shadow-z0 .box-color {
  box-shadow: 0 0 1px rgba(0, 0, 0, .15)
}

.box-shadow-z1,
.box-shadow-z1 .box,
.box-shadow-z1 .box-color {
  box-shadow: 0 1px 2px rgba(0, 0, 0, .1), 0 -1px 0 rgba(0, 0, 0, .02)
}

.box-shadow-z2,
.box-shadow-z2 .box,
.box-shadow-z2 .box-color {
  box-shadow: 0 1px 2px rgba(0, 0, 0, .15), 0 -1px 0 rgba(0, 0, 0, .02)
}

.box-shadow-z3,
.box-shadow-z3 .box,
.box-shadow-z3 .box-color {
  box-shadow: 0 2px 4px rgba(0, 0, 0, .1), 0 -1px 0 rgba(0, 0, 0, .02)
}

.box-shadow-z4,
.box-shadow-z4 .box,
.box-shadow-z4 .box-color {
  box-shadow: 0 2px 5px rgba(0, 0, 0, .26), 0 -1px 0 rgba(0, 0, 0, .02)
}

.box-radius-1x,
.box-radius-1x .box,
.box-radius-1x .box-color {
  border-radius: 2px
}

.box-radius-2x,
.box-radius-2x .box,
.box-radius-2x .box-color {
  border-radius: 3px
}

.box-radius-3x,
.box-radius-3x .box,
.box-radius-3x .box-color {
  border-radius: 4px
}

.ui-check {
  position: relative;
  padding-left: 20px
}

.ui-check input {
  opacity: 0;
  position: absolute;
  z-index: -1
}

.ui-check input:checked+i:before {
  left: 5px;
  top: 5px;
  width: 6px;
  height: 6px;
  background-color: #0cc2aa
}

.ui-check input:checked+span .active {
  display: inherit
}

.ui-check input[type=radio]+i,
.ui-check input[type=radio]+i:before {
  border-radius: 50%
}

.ui-check input[disabled]+i,
fieldset[disabled] .ui-check input+i {
  border-color: rgba(134, 143, 152, .2)
}

.ui-check input[disabled]+i:before,
fieldset[disabled] .ui-check input+i:before {
  background-color: rgba(134, 143, 152, .2)
}

.ui-check>i {
  width: 16px;
  height: 16px;
  line-height: 1;
  box-shadow: 0 0 1px rgba(120, 130, 140, .35);
  margin-top: -2px;
  display: inline-block;
  margin-right: 4px;
  background-clip: padding-box;
  position: relative
}

.ui-check>i:before {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 0;
  height: 0;
  background-color: transparent
}

.ui-check>span .active {
  display: none
}

.ui-check.ui-check-color input:checked+i:before {
  background-color: #fff
}

.ui-check-md input:checked+i:before {
  left: 6px;
  top: 6px
}

.ui-check-md>i {
  width: 18px;
  height: 18px
}

.ui-check-lg input:checked+i:before {
  width: 12px;
  height: 12px;
  left: 9px;
  top: 9px
}

.app-footer,
.app-header {
  left: 0;
  right: 0;
  margin: inherit
}

.ui-check-lg>i {
  width: 30px;
  height: 30px
}

[data-layout],
[layout] {
  box-sizing: border-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex
}

[data-layout=column],
[layout=column] {
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column
}

[data-layout=row],
[layout=row] {
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row
}

[data-flex],
[flex] {
  box-sizing: border-box;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1
}

html {
  height: 100%
}

body {
  height: auto;
  min-height: 100%;
  position: relative
}

.app-aside,
.app-fixed .app {
  height: 100%
}

.app-aside {
  position: fixed !important;
  z-index: 1030;
  float: left
}

.app-content {
  box-shadow: none
}

.app-content::after {
  display: block;
  clear: both
}

.app-header {
  position: fixed;
  top: 0;
  z-index: 1020
}

.app-header~.app-body {
  padding-top: 3.5rem
}

.app-header.navbar-md~.app-body {
  padding-top: 4rem
}

.app-header.navbar-sm~.app-body {
  padding-top: 3rem
}

.app-body-inner {
  padding-top: inherit !important;
  padding-bottom: inherit !important;
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: auto
}

.app-footer {
  position: absolute;
  bottom: 0;
  z-index: 1
}

.app-footer:not(.hide)~.app-body {
  padding-bottom: 3.125rem
}

.app-fixed {
  overflow: hidden
}

.app-fixed .app-content {
  position: relative;
  min-height: 100%
}

.app-fixed .app-footer {
  margin-left: 0
}

@media (min-width:992px) {
  .app-aside {
    opacity: 1;
    display: block !important
  }

  .app-aside,
  .app-aside .scroll {
    width: 12.5rem
  }

  .app-aside.lg:not(.folded),
  .app-aside.lg:not(.folded) .scroll {
    width: 13.75rem
  }

  .app-aside.lg:not(.hide)~.app-content {
    margin-left: 13.75rem
  }

  .app-aside.sm:not(.folded),
  .app-aside.sm:not(.folded) .scroll {
    width: 11.25rem
  }

  .app-aside.sm:not(.hide)~.app-content {
    margin-left: 11.25rem
  }

  .app-aside.folded {
    width: 4rem
  }

  .app-aside.folded:not(.hide)~.app-content {
    margin-left: 4rem
  }

  .app-aside.folded.md {
    width: 5rem
  }

  .app-aside.folded.md:not(.hide)~.app-content {
    margin-left: 5rem
  }

  .app-aside:not(.hide)~.app-content {
    margin-left: 12.5rem
  }

  .app-aside .left {
    position: absolute;
    right: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }

  .hide-scroll {
    width: auto;
    margin-right: -17px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch
  }
}

@media (max-width:991px) {
  .app-aside.modal {
    width: 100%;
    position: fixed;
    z-index: 1050
  }

  .app-aside .left {
    position: fixed;
    width: 304px
  }

  .app .container,
  body.container {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0
  }
}

@media print {

  .app-aside,
  .app-footer,
  .app-header,
  .switcher {
    display: none
  }
}

.ie9 .app-aside {
  position: static !important
}

.ie9 .app-aside .left {
  width: inherit;
  position: absolute
}

@media (min-width:992px) {
  body.container {
    box-shadow: 0 1px 1px rgba(0, 0, 0, .1)
  }

  body.container,
  body.container .container {
    padding-left: 0;
    padding-right: 0
  }

  body.container .app-aside {
    left: inherit;
    right: auto
  }

  body.container .app-aside.lg~.app-content .app-header {
    width: 45rem
  }

  body.container .app-aside.sm~.app-content .app-header {
    width: 47.5rem
  }

  body.container .app-aside.folded~.app-content .app-header {
    width: 54.75rem
  }

  body.container .app-aside.folded.md~.app-content .app-header {
    width: 53.75rem
  }

  body.container .app-aside~.app-content .app-header {
    width: 46.25rem
  }

  body.container .app-header {
    left: inherit;
    right: auto;
    margin-left: 0;
    width: 58.75rem
  }
}

@media (min-width:1200px) {
  body.container .app-aside.lg~.app-content .app-header {
    width: 57.5rem
  }

  body.container .app-aside.sm~.app-content .app-header {
    width: 60rem
  }

  body.container .app-aside.folded~.app-content .app-header {
    width: 67.25rem
  }

  body.container .app-aside.folded.md~.app-content .app-header {
    width: 66.25rem
  }

  body.container .app-aside~.app-content .app-header {
    width: 58.75rem
  }

  body.container .app-header {
    width: 71.25rem
  }
}

.list {
  padding-left: 0;
  padding-right: 0;
  border-radius: 3px
}

.list.no-border {
  padding-top: 8px;
  padding-bottom: 8px
}

.list-item {
  display: block;
  position: relative;
  padding: 12px 16px
}

.list-item::after {
  display: block;
  clear: both
}

.no-border .list-body:after,
.pace-inactive {
  display: none
}

.list-item:last-child .list-body:after {
  border: none
}

.no-border .list-item {
  padding-top: 8px;
  padding-bottom: 8px
}

.no-padding .list-item {
  padding-left: 0;
  padding-right: 0
}

.list-left {
  float: left;
  padding-right: 1rem
}

.list-left+.list-body {
  margin-left: 56px
}

.list-body h3 {
  font-size: 16px;
  margin: 0 0 3px;
  font-weight: 400
}

.list-body:after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom: 1px solid rgba(120, 130, 140, .065)
}

.inset .list-body:after {
  left: 72px
}

.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none
}

.pace .pace-progress {
  background: #0cc2aa;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px
}

.pace .pace-activity {
  display: block;
  position: fixed;
  z-index: 2000;
  top: 5px;
  right: 5px;
  width: 14px;
  height: 14px;
  border: 2px solid transparent;
  border-top-color: #0cc2aa;
  border-left-color: #0cc2aa;
  border-radius: 10px;
  -webkit-animation: loading-bar-spinner .4s linear infinite;
  -moz-animation: loading-bar-spinner .4s linear infinite;
  -ms-animation: loading-bar-spinner .4s linear infinite;
  -o-animation: loading-bar-spinner .4s linear infinite;
  animation: loading-bar-spinner .4s linear infinite
}

.nav-icon i,
.navside .nav li {
  position: relative
}

.navside,
.navside .nav {
  border: inherit
}

@-webkit-keyframes loading-bar-spinner {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@-moz-keyframes loading-bar-spinner {
  0% {
    -moz-transform: rotate(0);
    transform: rotate(0)
  }

  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@-o-keyframes loading-bar-spinner {
  0% {
    -o-transform: rotate(0);
    transform: rotate(0)
  }

  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@-ms-keyframes loading-bar-spinner {
  0% {
    -ms-transform: rotate(0);
    transform: rotate(0)
  }

  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes loading-bar-spinner {
  0% {
    transform: rotate(0)
  }

  100% {
    transform: rotate(360deg)
  }
}

.navside ul {
  padding-left: 0;
  padding-right: 0;
  list-style: none;
  display: block
}

.navside [flex] {
  overflow: auto
}

.navside .nav li {
  border: inherit
}

.navside .nav li li a {
  line-height: 2rem;
  padding-left: 3.5rem
}

.navside .nav li li a .nav-text {
  padding: .4375rem 0
}

.navside .nav li li li a {
  padding-left: 4.5rem
}

.navside .nav li li li li a {
  padding-left: 5.5rem
}

.navside .nav li a,
.navside .nav-header {
  padding: 0 1rem;
  line-height: 2.25rem
}

.navside .nav li a {
  display: block;
  position: relative
}

.navside .nav li a::after {
  display: block;
  clear: both
}

.navside .nav li a:focus,
.navside .nav li a:hover,
.navside .nav li.active {
  background-color: rgba(0, 0, 0, .065)
}

.navside .nav li.active>a {
  background-color: transparent
}

.navside .nav li.active>a .nav-caret i {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg)
}

.navside .nav-header:focus,
.navside .nav-header:hover {
  background-color: transparent !important
}

.navside .nav-header:after {
  display: none
}

.nav-text,
.nav-text small,
.nav-text span {
  display: block
}

.navside .navbar-brand {
  float: none;
  margin-right: 0
}

.nav-fold {
  padding: .75rem 1rem
}

.nav-text {
  line-height: 1.125rem;
  padding: .5625rem 0
}

.nav>li>a .nav-text {
  font-weight: 500
}

.navside .nav-text {
  opacity: .75
}

.navside .active>a>.nav-text,
.navside a:focus>.nav-text,
.navside a:hover>.nav-text {
  opacity: 1
}

.navbar .nav-text {
  line-height: 1;
  display: inline-block;
  padding: 0
}

.navbar .nav-text span.text-xs {
  margin-top: .25rem
}

.nav-icon {
  float: left;
  line-height: inherit;
  margin-right: 1rem;
  top: 0;
  min-width: 1.5rem
}

.nav-icon i img,
.nav-icon i svg {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%
}

.navbar .nav-icon {
  margin-right: .5rem
}

.nav-label {
  float: right;
  margin-left: 1rem;
  font-style: normal;
  z-index: 1
}

.navbar .nav-label {
  margin-left: .5rem
}

.nav-caret {
  float: right;
  margin-left: 1rem;
  opacity: .45
}

.blur-5,
.opacity {
  opacity: .5
}

.nav-caret i {
  -webkit-transition: transform .3s ease-in-out;
  transition: transform .3s ease-in-out
}

.navbar .nav-caret {
  margin-left: .5rem
}

.nav-sub {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height .4s ease 0s;
  transition: max-height .4s ease 0s
}

.active>.nav-sub {
  max-height: 50rem
}

@media (min-width:992px) {

  .folded .hidden-folded,
  .folded .nav li ul,
  .folded .nav>li>a .nav-caret,
  .folded .nav>li>a .nav-text {
    display: none
  }

  .folded .nav>li>a,
  .folded .navbar {
    text-align: center;
    padding-left: 4px;
    padding-right: 4px
  }

  .folded,
  .folded .scroll {
    width: 4rem
  }

  .folded.md,
  .folded.md .scroll {
    width: 5rem
  }

  .folded .nav>li>a .nav-label {
    position: absolute;
    top: -6px;
    right: 6px
  }

  .folded .nav>li>a .nav-icon {
    float: none;
    line-height: 2.5rem;
    margin-left: 0;
    margin-right: 0;
    position: relative
  }

  .folded.show-text .nav>li>a .nav-text {
    display: block;
    margin-top: -18px;
    font-size: .85em
  }

  .folded .navbar-brand {
    float: none;
    margin: 0
  }

  .folded .nav-stacked .nav {
    margin: 0 12px
  }

  .folded .nav-fold {
    padding: 8px 16px
  }

  .folded .nav-fold .pull-left {
    float: none !important;
    margin: 0
  }

  .folded .nav-fold .pull-left img {
    width: 100%;
    height: auto
  }

  .folded.nav-expand {
    -webkit-transition: width .4s cubic-bezier(.25, .8, .25, 1);
    transition: width .4s cubic-bezier(.25, .8, .25, 1);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute
  }

  .folded.nav-expand.active,
  .folded.nav-expand.active .scroll,
  .folded.nav-expand:focus,
  .folded.nav-expand:focus .scroll,
  .folded.nav-expand:hover,
  .folded.nav-expand:hover .scroll {
    width: 12.5rem !important
  }

  .folded.nav-expand.active>*,
  .folded.nav-expand:focus>*,
  .folded.nav-expand:hover>* {
    width: 12.5rem
  }

  .folded.nav-expand.active .hidden-folded,
  .folded.nav-expand:focus .hidden-folded,
  .folded.nav-expand:hover .hidden-folded {
    display: block
  }

  .folded.nav-expand.active .hidden-folded.inline,
  .folded.nav-expand:focus .hidden-folded.inline,
  .folded.nav-expand:hover .hidden-folded.inline {
    display: inline-block
  }

  .folded.nav-expand.active .nav li ul,
  .folded.nav-expand:focus .nav li ul,
  .folded.nav-expand:hover .nav li ul {
    display: inherit
  }

  .folded.nav-dropdown .nav>li:focus>ul,
  .folded.nav-dropdown .nav>li:hover>ul,
  .folded.nav-expand.active .nav>li>a .nav-caret,
  .folded.nav-expand.active .nav>li>a .nav-text,
  .folded.nav-expand:focus .nav>li>a .nav-caret,
  .folded.nav-expand:focus .nav>li>a .nav-text,
  .folded.nav-expand:hover .nav>li>a .nav-caret,
  .folded.nav-expand:hover .nav>li>a .nav-text,
  .nav-dropup .nav>li:focus>ul,
  .nav-dropup .nav>li:hover>ul {
    display: block
  }

  .folded.nav-expand.active .nav>li>a,
  .folded.nav-expand:focus .nav>li>a,
  .folded.nav-expand:hover .nav>li>a {
    text-align: left;
    padding-left: 16px;
    padding-right: 16px
  }

  .folded.nav-expand.active .nav>li>a .nav-text,
  .folded.nav-expand:focus .nav>li>a .nav-text,
  .folded.nav-expand:hover .nav>li>a .nav-text {
    font-size: 1em;
    margin: 0
  }

  .folded.nav-expand.active .nav>li>a .nav-label,
  .folded.nav-expand:focus .nav>li>a .nav-label,
  .folded.nav-expand:hover .nav>li>a .nav-label {
    position: static
  }

  .folded.nav-expand.active .nav>li>a .nav-icon,
  .folded.nav-expand:focus .nav>li>a .nav-icon,
  .folded.nav-expand:hover .nav>li>a .nav-icon {
    float: left;
    line-height: inherit;
    margin-right: 16px
  }

  .folded.nav-expand.active .navbar,
  .folded.nav-expand:focus .navbar,
  .folded.nav-expand:hover .navbar {
    text-align: left;
    padding-left: 1rem;
    padding-right: 1rem
  }

  .folded.nav-expand.active .nav-stacked .nav,
  .folded.nav-expand:focus .nav-stacked .nav,
  .folded.nav-expand:hover .nav-stacked .nav {
    margin: 0 8px
  }

  .folded.nav-expand.active .nav-stacked .nav>li>a,
  .folded.nav-expand:focus .nav-stacked .nav>li>a,
  .folded.nav-expand:hover .nav-stacked .nav>li>a {
    padding: 0 8px
  }

  .folded.nav-expand.active .nav-fold,
  .folded.nav-expand:focus .nav-fold,
  .folded.nav-expand:hover .nav-fold {
    padding: 12px 16px
  }

  .folded.nav-expand.active .nav-fold .pull-left,
  .folded.nav-expand:focus .nav-fold .pull-left,
  .folded.nav-expand:hover .nav-fold .pull-left {
    float: left !important
  }

  .folded.nav-expand.active .nav-fold .pull-left img,
  .folded.nav-expand:focus .nav-fold .pull-left img,
  .folded.nav-expand:hover .nav-fold .pull-left img {
    height: auto
  }

  .folded.nav-expand.active .nav-fold .pull-left img.w-40,
  .folded.nav-expand:focus .nav-fold .pull-left img.w-40,
  .folded.nav-expand:hover .nav-fold .pull-left img.w-40 {
    width: 40px
  }

  .folded.nav-expand.active .nav-fold .pull-left img.w-48,
  .folded.nav-expand:focus .nav-fold .pull-left img.w-48,
  .folded.nav-expand:hover .nav-fold .pull-left img.w-48 {
    width: 48px
  }

  .folded.nav-dropdown .row-body,
  .folded.nav-dropdown [flex],
  .folded.nav-dropdown.modal,
  .nav-dropup .row-body,
  .nav-dropup [flex],
  .nav-dropup.modal {
    overflow: visible !important
  }

  .folded.nav-dropdown .nav>li>ul,
  .nav-dropup .nav>li>ul {
    display: none;
    overflow: visible;
    max-height: 999px;
    color: rgba(255, 255, 255, .87);
    background-color: rgba(0, 0, 0, .87);
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .15);
    position: absolute;
    left: 100%;
    top: 0;
    padding: 6px 0
  }

  .folded.nav-dropdown .nav>li>ul a,
  .nav-dropup .nav>li>ul a {
    padding-left: 16px !important;
    padding-right: 16px !important;
    min-width: 160px
  }

  .folded.nav-dropdown .nav>li>ul li.active ul,
  .nav-dropup .nav>li>ul li.active ul {
    display: block
  }

  .folded.nav-dropdown .nav>li>ul .nav-text,
  .nav-dropup .nav>li>ul .nav-text {
    padding: 6px 0 !important
  }

  .folded.nav-dropdown .nav>li .nav-mega,
  .nav-dropup .nav>li .nav-mega {
    width: 320px
  }

  .folded.nav-dropdown .nav>li .nav-mega>li,
  .nav-dropup .nav>li .nav-mega>li {
    width: 160px;
    float: left
  }

  .folded.nav-dropdown .nav>li .nav-mega.nav-mega-3,
  .nav-dropup .nav>li .nav-mega.nav-mega-3 {
    width: 480px
  }
}

.easyPieChart,
.nav-center,
.sl-icon>i,
.ui-icon {
  text-align: center
}

.clear,
.item-bg,
.list-icon div {
  overflow: hidden
}

.nav-border .nav>li.active:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  border-left-color: inherit;
  border-left-width: 3px;
  border-left-style: solid
}

.nav-stacked .nav {
  margin: 0 8px
}

.nav-stacked .nav>li>a {
  line-height: 2rem;
  padding: 0 .5rem;
  margin: 2px 0
}

.nav-stacked .nav>li>a .nav-text {
  padding: .4375rem 0
}

.nav-stacked .nav>li li a {
  padding-left: 3rem
}

.nav-stacked .nav>li li li a {
  padding-left: 4rem
}

.nav-stacked .nav>li li li li a {
  padding-left: 5rem
}

.nav-stacked .nav>li.active>a {
  color: rgba(255, 255, 255, .87);
  background-color: #0cc2aa
}

.nav-stacked .nav li a {
  border-radius: 3px
}

.nav-stacked .nav .nav-header {
  padding-left: 8px;
  padding-right: 8px
}

.nav-light .nav .nav-icon i img,
.nav-light .nav .nav-icon i svg {
  display: block
}

.nav-center .nav-icon {
  display: none
}

.nav-center .nav-caret {
  position: absolute;
  right: 10px
}

.nav-center .nav-label {
  position: absolute;
  padding-left: 10px
}

.nav-center .nav a {
  padding-left: 16px !important
}

.nav-center .nav-fold .pull-left {
  float: none !important;
  margin-bottom: 10px;
  display: inline-block
}

.tl-date,
.tl-wrap:before {
  float: left;
  position: relative
}

.nav-active-bg .nav-link.active,
.nav-active-bg .nav>li.active>a {
  color: rgba(0, 0, 0, .87) !important;
  background-color: #f0f0f0 !important
}

.nav-active-dark .nav-link.active,
.nav-active-dark .nav>li.active>a {
  color: rgba(255, 255, 255, .87) !important;
  background-color: #2e3e4e !important
}

.nav-active-black .nav-link.active,
.nav-active-black .nav>li.active>a {
  color: rgba(255, 255, 255, .87) !important;
  background-color: #2a2b3c !important
}

.nav-active-primary .nav-link.active,
.nav-active-primary .nav>li.active>a {
  color: rgba(255, 255, 255, .87) !important;
  background-color: #0cc2aa !important
}

.nav-active-accent .nav-link.active,
.nav-active-accent .nav>li.active>a {
  color: rgba(255, 255, 255, .87) !important;
  background-color: #a88add !important
}

.nav-active-warn .nav-link.active,
.nav-active-warn .nav>li.active>a {
  color: rgba(255, 255, 255, .87) !important;
  background-color: #fcc100 !important
}

.nav-active-success .nav-link.active,
.nav-active-success .nav>li.active>a {
  color: rgba(255, 255, 255, .87) !important;
  background-color: #6cc788 !important
}

.nav-active-info .nav-link.active,
.nav-active-info .nav>li.active>a {
  color: rgba(255, 255, 255, .87) !important;
  background-color: #6887ff !important
}

.nav-active-warning .nav-link.active,
.nav-active-warning .nav>li.active>a {
  color: rgba(255, 255, 255, .87) !important;
  background-color: #f77a99 !important
}

.nav-active-danger .nav-link.active,
.nav-active-danger .nav>li.active>a {
  color: rgba(255, 255, 255, .87) !important;
  background-color: #f44455 !important
}

.nav-active-light .nav-link.active,
.nav-active-light .nav>li.active>a {
  color: rgba(0, 0, 0, .87) !important;
  background-color: #f8f8f8 !important
}

.nav-active-white .nav-link.active,
.nav-active-white .nav>li.active>a {
  color: rgba(0, 0, 0, .87) !important;
  background-color: #fff !important
}

.nav-active-red .nav-link.active,
.nav-active-red .nav>li.active>a {
  color: rgba(255, 255, 255, .87) !important;
  background-color: #f44336 !important
}

.nav-active-pink .nav-link.active,
.nav-active-pink .nav>li.active>a {
  color: rgba(255, 255, 255, .87) !important;
  background-color: #e91e63 !important
}

.nav-active-purple .nav-link.active,
.nav-active-purple .nav>li.active>a {
  color: rgba(255, 255, 255, .87) !important;
  background-color: #9c27b0 !important
}

.nav-active-deep-purple .nav-link.active,
.nav-active-deep-purple .nav>li.active>a {
  color: rgba(255, 255, 255, .87) !important;
  background-color: #673ab7 !important
}

.nav-active-indigo .nav-link.active,
.nav-active-indigo .nav>li.active>a {
  color: rgba(255, 255, 255, .87) !important;
  background-color: #3f51b5 !important
}

.nav-active-blue .nav-link.active,
.nav-active-blue .nav>li.active>a {
  color: rgba(255, 255, 255, .87) !important;
  background-color: #2196f3 !important
}

.nav-active-light-blue .nav-link.active,
.nav-active-light-blue .nav>li.active>a {
  color: rgba(255, 255, 255, .87) !important;
  background-color: #03a9f4 !important
}

.nav-active-cyan .nav-link.active,
.nav-active-cyan .nav>li.active>a {
  color: rgba(255, 255, 255, .87) !important;
  background-color: #00bcd4 !important
}

.nav-active-teal .nav-link.active,
.nav-active-teal .nav>li.active>a {
  color: rgba(255, 255, 255, .87) !important;
  background-color: #009688 !important
}

.nav-active-green .nav-link.active,
.nav-active-green .nav>li.active>a {
  color: rgba(255, 255, 255, .87) !important;
  background-color: #4caf50 !important
}

.nav-active-light-green .nav-link.active,
.nav-active-light-green .nav>li.active>a {
  color: rgba(255, 255, 255, .87) !important;
  background-color: #8bc34a !important
}

.nav-active-lime .nav-link.active,
.nav-active-lime .nav>li.active>a {
  color: rgba(255, 255, 255, .87) !important;
  background-color: #cddc39 !important
}

.nav-active-yellow .nav-link.active,
.nav-active-yellow .nav>li.active>a {
  color: rgba(0, 0, 0, .87) !important;
  background-color: #ffeb3b !important
}

.nav-active-amber .nav-link.active,
.nav-active-amber .nav>li.active>a {
  color: rgba(255, 255, 255, .87) !important;
  background-color: #ffc107 !important
}

.nav-active-orange .nav-link.active,
.nav-active-orange .nav>li.active>a {
  color: rgba(255, 255, 255, .87) !important;
  background-color: #ff9800 !important
}

.nav-active-deep-orange .nav-link.active,
.nav-active-deep-orange .nav>li.active>a {
  color: rgba(255, 255, 255, .87) !important;
  background-color: #ff5722 !important
}

.nav-active-brown .nav-link.active,
.nav-active-brown .nav>li.active>a {
  color: rgba(255, 255, 255, .87) !important;
  background-color: #795548 !important
}

.nav-active-blue-grey .nav-link.active,
.nav-active-blue-grey .nav>li.active>a {
  color: rgba(255, 255, 255, .87) !important;
  background-color: #607d8b !important
}

.nav-active-grey .nav-link.active,
.nav-active-grey .nav>li.active>a {
  color: rgba(255, 255, 255, .87) !important;
  background-color: #424242 !important
}

.ui-icon {
  display: inline-block;
  height: 1em;
  background: 0 0 !important
}

.ui-icon:before {
  position: relative;
  z-index: 1
}

.ui-icon:after {
  content: "";
  border-width: 1px;
  border-style: solid;
  border-color: inherit;
  border-radius: 2em;
  width: 2em;
  height: 2em;
  top: -1.5em;
  display: block;
  position: relative;
  z-index: 0;
  background-clip: padding-box
}

.ui-icon.b-2x:after {
  border-width: 2px
}

.ui-icon.b-3x:after {
  border-width: 3px
}

.switcher,
.switcher .sw-btn {
  border: 1px solid rgba(120, 120, 120, .1);
  background-clip: padding-box
}

.ui-icon.ui-icon-sm:after {
  width: 1.7em;
  height: 1.7em;
  top: -1.35em
}

.ui-icon.primary:after {
  background-color: #0cc2aa
}

.ui-icon.success:after {
  background-color: #6cc788
}

.ui-icon.info:after {
  background-color: #6887ff
}

.ui-icon.warning:after {
  background-color: #f77a99
}

.ui-icon.danger:after {
  background-color: #f44455
}

.ui-icon.accent:after {
  background-color: #a88add
}

.ui-icon.dark:after {
  background-color: #2e3e4e
}

.ui-icon.light:after {
  background-color: #f8f8f8
}

.ui-icon.white:after {
  background-color: #fff
}

#flotTip,
.flotTip,
.jqstooltip {
  background-color: rgba(0, 0, 0, .8) !important
}

.item {
  position: relative
}

.item .bottom,
.item .center,
.item .top,
.item-bg,
.item-bg img,
.item-overlay {
  position: absolute
}

.item .top {
  right: 0;
  left: 0;
  top: 0
}

.item .bottom {
  right: 0;
  left: 0;
  bottom: 0
}

.item .center {
  right: 0;
  left: 0;
  top: 42%
}

.item-overlay {
  right: 0;
  left: 0;
  display: none
}

.item-overlay .item:focus,
.item-overlay.active,
.item:hover .item-overlay {
  display: block
}

.item-overlay.w-full {
  text-align: center;
  top: 0;
  bottom: 0
}

.item-bg {
  width: 100%;
  height: 100%
}

.item-bg img {
  width: 110%;
  left: 50%;
  top: 50%;
  margin-left: -55%;
  margin-top: -55%
}

.blur {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px)
}

.blur-5 {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px)
}

.opacity-3 {
  opacity: .3
}

.switcher {
  z-index: 1050;
  position: fixed;
  top: 78px;
  right: -240px;
  width: 240px;
  -webkit-transition: right .2s ease;
  transition: right .2s ease
}

.switcher.active {
  right: -2px;
  z-index: 1060
}

.switcher .sw-btn {
  position: absolute;
  left: -43px;
  top: -1px;
  padding: 10px 15px;
  z-index: 1045;
  border-right-width: 0
}

#sw-demo {
  top: 118px
}

#sw-demo.active {
  top: 78px
}

.list-icon i {
  margin-right: 16px
}

.list-icon div {
  line-height: 40px
}

.jqstooltip {
  border: 1px solid #000 !important;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  padding: 5px 10px !important;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box
}

.easyPieChart {
  position: relative
}

.easyPieChart>div {
  position: absolute;
  width: 100%;
  line-height: 1;
  top: 40%
}

.easyPieChart>div img {
  margin-top: -4px
}

#flotTip,
.flotTip {
  padding: 4px 10px;
  border: 1px solid #000 !important;
  z-index: 100;
  font-size: 12px;
  color: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px
}

.legendColorBox>div {
  border: none !important;
  margin: 5px
}

.legendColorBox>div>div {
  border-radius: 10px
}

.st-sort-ascent:before {
  content: '\25B2'
}

.st-sort-descent:before {
  content: '\25BC'
}

.st-selected td {
  background: rgba(0, 0, 0, .065)
}

.black .btn-default.form-control,
.dark .btn-default.form-control,
.grey .btn-default.form-control {
  background: 0 0 !important;
  background-color: rgba(255, 255, 255, .1) !important;
  color: inherit !important
}

.ui-select-bootstrap>.ui-select-choices {
  overflow-y: scroll
}

.box .dataTables_wrapper {
  padding-top: 10px
}

.box .dataTables_wrapper>.row {
  margin: 0;
  width: 100%
}

.box .dataTables_wrapper>.row>.col-sm-12 {
  padding: 0
}

.dataTables_wrapper.form-inline .form-control {
  width: auto
}

div.dataTables_filter label,
div.dataTables_length label,
div.dataTables_paginate ul.pagination {
  display: inline-block
}

.footable-odd {
  background-color: rgba(0, 0, 0, .025) !important
}

.note-editor {
  border-width: 0 !important;
  overflow: visible !important;
  background-color: inherit
}

.note-toolbar .note-color .dropdown-toggle {
  padding-left: 2px !important
}

.note-editor .note-editing-area .note-editable,
.note-editor .note-statusbar {
  background-color: transparent !important;
  color: inherit !important
}

.note-toolbar {
  background-color: rgba(120, 130, 140, .1)
}

.ie .ie-show {
  display: block
}

.nav a.waves-effect {
  -webkit-mask-image: -webkit-radial-gradient(circle, #fff 100%, #000 100%)
}

.sortable-placeholder {
  list-style: none;
  border: 1px dashed #fff;
  min-height: 40px
}

.abn-tree {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -webkit-flex-direction: column !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important
}

.input-group>.ui-select-bootstrap.dropdown,
div.input-group>.select2-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%
}

.input-group>.ui-select-bootstrap.dropdown .form-control,
div.input-group>.select2-container>.selection {
  width: 100%
}

.streamline {
  position: relative;
  border-color: rgba(120, 130, 140, .13)
}

.streamline .sl-icon:before,
.streamline .sl-item:before,
.streamline:after {
  content: '';
  position: absolute;
  border-color: inherit;
  border-width: 3px;
  border-style: solid;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  margin-left: -4px;
  top: 6px;
  left: 0
}

.sl-item::after,
.ui-switch i:before {
  content: ""
}

.streamline:after {
  top: auto;
  bottom: 0
}

.sl-icon {
  position: absolute;
  left: -10px;
  z-index: 1;
  border: inherit
}

.sl-item,
.sl-left,
.ui-switch {
  position: relative
}

.sl-icon>i {
  width: 20px;
  height: 20px;
  display: table-cell;
  color: #fff
}

.streamline .sl-icon:before {
  width: 20px;
  height: 20px;
  border-width: 10px;
  margin: 0 !important;
  top: 0 !important
}

.sl-item {
  border-color: rgba(120, 130, 140, .13);
  padding-bottom: 1px
}

.sl-item::after {
  display: block;
  clear: both
}

.sl-item:after {
  top: 2px;
  bottom: auto
}

.sl-item.b-l {
  margin-left: -1px
}

.sl-left {
  float: left;
  z-index: 1;
  margin-left: -20px;
  margin-bottom: 24px
}

.sl-author,
.sl-footer {
  margin-bottom: 10px
}

.sl-left img {
  max-width: 40px
}

.sl-left+.sl-content {
  margin-left: 36px
}

.sl-content {
  margin-left: 24px;
  padding-bottom: 16px
}

.sl-date {
  font-size: .85em
}

.ui-switch {
  display: inline-block;
  width: 32px;
  height: 18px;
  border-radius: 30px;
  background-color: #6cc788;
  margin: 0
}

.ui-switch i:after,
.ui-switch i:before {
  position: absolute;
  background-color: #fff
}

.ui-switch input {
  position: absolute;
  opacity: 0
}

.ui-switch input:checked+i:before {
  top: 50%;
  bottom: 50%;
  left: 50%;
  right: 5px;
  border-width: 0;
  border-radius: 5px
}

.ui-switch input:checked+i:after {
  margin-left: 15px
}

.ui-switch i:before {
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  border: 1px solid #f0f0f0;
  border-radius: 30px;
  -webkit-transition: all .2s;
  transition: all .2s
}

.tl-wrap:before,
.ui-switch i:after {
  border-radius: 50%;
  content: ""
}

.ui-switch i:after {
  width: 16px;
  top: 1px;
  bottom: 1px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, .25);
  -webkit-transition: margin .3s;
  transition: margin .3s
}

.ui-switch-md {
  width: 40px;
  height: 24px
}

.ui-switch-md input:checked+i:after {
  margin-left: 17px
}

.ui-switch-md i:after {
  width: 22px
}

.ui-switch-lg {
  width: 50px;
  height: 30px
}

.ui-switch-lg input:checked+i:after {
  margin-left: 21px
}

.ui-switch-lg i:after {
  width: 28px
}

.timeline {
  margin: 0;
  padding: 0
}

.m-t,
.m-y {
  margin-top: 1rem !important
}

.tl-item {
  display: block
}

.tl-item::after {
  display: block;
  content: "";
  clear: both
}

.visible-left {
  display: none
}

.tl-wrap {
  display: block;
  margin-left: 6em;
  padding: 15px 0 15px 20px;
  border-style: solid;
  border-color: rgba(120, 130, 140, .13);
  border-width: 0 0 0 2px
}

.tl-wrap::after {
  display: block;
  content: "";
  clear: both
}

.tl-wrap:before {
  top: 15px;
  margin-left: -26px;
  width: 10px;
  height: 10px;
  border-color: inherit;
  border-width: 3px;
  border-style: solid;
  background: #f8f8f8;
  box-shadow: 0 0 0 4px #f0f0f0
}

.tl-date {
  top: 10px;
  margin-left: -7.5em;
  display: block;
  width: 4.5em;
  text-align: right
}

.tl-content {
  display: inline-block;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px
}

.p-t,
.p-y {
  padding-top: 1rem !important
}

.p-b,
.p-y {
  padding-bottom: 1rem !important
}

.tl-content.block {
  display: block;
  width: 100%
}

.tl-content.panel {
  margin-bottom: 0
}

.m-b,
.m-y {
  margin-bottom: 1rem !important
}

.tl-header {
  display: block;
  width: 12em;
  text-align: center;
  margin-left: 1px
}

.timeline-center .tl-item {
  margin-left: 50%
}

.timeline-center .tl-item .tl-wrap {
  margin-left: -2px
}

.timeline-center .tl-header {
  width: auto;
  margin-left: -1px
}

.timeline-center .tl-left {
  margin-left: 0;
  margin-right: 50%
}

.timeline-center .tl-left .hidden-left {
  display: none !important
}

.timeline-center .tl-left .visible-left {
  display: inherit
}

.timeline-center .tl-left .tl-wrap {
  float: right;
  margin-right: 0;
  border-left-width: 0;
  border-right-width: 2px;
  padding-left: 0;
  padding-right: 20px
}

.p-l,
.p-x {
  padding-left: 1rem !important
}

.p-r,
.p-x {
  padding-right: 1rem !important
}

.timeline-center .tl-left .tl-wrap:before {
  float: right;
  margin-left: 0;
  margin-right: -26px
}

.timeline-center .tl-left .tl-date {
  float: right;
  margin-left: 0;
  margin-right: -8.5em;
  text-align: left
}

.md-btn,
.w-24,
.w-32,
.w-40,
.w-48,
.w-56 {
  text-align: center
}

.lter {
  background-color: rgba(255, 255, 255, .035)
}

.dker {
  background-color: rgba(0, 0, 0, .035)
}

.light {
  background-color: #f8f8f8
}

.dark-white,
.white {
  background-color: #fff
}

.black,
.danger,
.dark,
.grey,
.info,
.primary,
.success,
.warn,
.warning {
  color: rgba(255, 255, 255, .87)
}

.black {
  background-color: #2a2b3c
}

.black .lt {
  background-color: #2f3044
}

.black .dk {
  background-color: #252635
}

.black .bg {
  background-color: #2a2b3c
}

.dark {
  background-color: #2e3e4e
}

.dark .lt {
  background-color: #334456
}

.dark .dk {
  background-color: #293846
}

.dark .bg {
  background-color: #2e3e4e
}

.grey {
  background-color: #424242
}

.grey .lt {
  background-color: #484848
}

.grey .dk {
  background-color: #3c3c3c
}

.grey .bg {
  background-color: #424242
}

.primary {
  background-color: #0cc2aa
}

.primary .lt {
  background-color: #0dceb5
}

.primary .dk {
  background-color: #0bb69f
}

.primary .bg {
  background-color: #0cc2aa
}

.accent {
  color: rgba(255, 255, 255, .87);
  background-color: #a88add
}

.accent .lt {
  background-color: #af94e0
}

.accent .dk {
  background-color: #a180da
}

.accent .bg {
  background-color: #a88add
}

.warn {
  background-color: #fcc100
}

.warn .lt {
  background-color: #ffc60a
}

.warn .dk {
  background-color: #efb700
}

.warn .bg {
  background-color: #fcc100
}

.success {
  background-color: #6cc788
}

.success .lt {
  background-color: #75cb8f
}

.success .dk {
  background-color: #63c381
}

.success .bg {
  background-color: #6cc788
}

.info {
  background-color: #6887ff
}

.info .lt {
  background-color: #7591ff
}

.info .dk {
  background-color: #5b7dff
}

.info .bg {
  background-color: #6887ff
}

.warning {
  background-color: #f77a99
}

.warning .lt {
  background-color: #f886a2
}

.warning .dk {
  background-color: #f66e90
}

.warning .bg {
  background-color: #f77a99
}

.danger {
  background-color: #f44455
}

.danger .lt {
  background-color: #f55060
}

.danger .dk {
  background-color: #f3384a
}

.danger .bg {
  background-color: #f44455
}

.blue {
  background-color: #2196f3
}

.blue .lt {
  background-color: #2d9cf4
}

.blue .dk {
  background-color: #1590f2
}

.blue .bg {
  background-color: #2196f3
}

.white-overlay {
  background-color: rgba(255, 255, 255, .85)
}

.black-overlay {
  background-color: rgba(0, 0, 0, .85)
}

.gd-overlay {
  background-color: transparent !important;
  background-image: linear-gradient(to bottom, transparent 0, rgba(0, 0, 0, .5) 100%);
  background-repeat: repeat-x
}

.bg-auto:before {
  content: "";
  position: absolute;
  width: inherit;
  top: 0;
  bottom: 0;
  background-color: inherit;
  border: inherit
}

.bg-auto.b-l:before {
  margin-left: -1px
}

.m-l,
.m-x {
  margin-left: 1rem !important
}

.bg-auto.b-r:before {
  margin-right: -1px
}

.m-r,
.m-x {
  margin-right: 1rem !important
}

.bg-clip {
  background-clip: padding-box
}

.no-bg {
  background-color: transparent !important
}

@media (max-width:767px) {
  .no-bg-xs {
    background-color: transparent
  }
}

.dark .box,
.dark .white {
  background-color: #354759
}

.dark.app {
  background-color: #2e3e4e
}

.black .box,
.black .white {
  background-color: #313347
}

.black.app {
  background-color: #2a2b3c
}

.grey .box,
.grey .white {
  background-color: #4b4b4b
}

.grey.app {
  background-color: #424242
}

.black .form-control,
.dark .form-control,
.grey .form-control {
  background: 0 0;
  background-color: rgba(255, 255, 255, .1);
  color: inherit
}

.black .form-control:focus,
.dark .form-control:focus,
.grey .form-control:focus {
  border-color: rgba(255, 255, 255, .125)
}

.black .input-group-btn .btn,
.dark .input-group-btn .btn,
.grey .input-group-btn .btn {
  background-color: rgba(255, 255, 255, .1) !important;
  color: inherit !important
}

.black .list-group,
.dark .list-group,
.grey .list-group {
  background-color: transparent
}

.pos-rlt {
  position: relative;
  z-index: 1
}

.pos-abt {
  position: absolute
}

.pos-fix {
  position: fixed !important
}

.pos-stc {
  position: static !important
}

.block {
  display: block
}

.block.hide {
  display: none
}

.pull-none {
  float: none
}

.inline {
  display: inline-block
}

.inline-16 {
  width: 16px;
  height: 16px
}

.inline-24 {
  width: 24px;
  height: 24px
}

.active>.auto .inline,
.active>.inline,
.none {
  display: none
}

.active>.auto .none,
.active>.none {
  display: inline-block
}

.hide {
  display: none !important
}

.circle,
.rounded {
  border-radius: 500px
}

.clear {
  display: block
}

.no-shadow {
  -webkit-box-shadow: none !important;
  box-shadow: none !important
}

@media (max-width:575px) {
  .pos-stc-xs {
    position: static !important
  }
}

.no-border {
  border-color: transparent;
  border-width: 0
}

.no-borders {
  border-width: 0 !important
}

.b {
  border-color: rgba(120, 130, 140, .13);
  background-clip: padding-box
}

.b-a {
  border: 1px solid rgba(120, 130, 140, .13)
}

.b-t {
  border-top: 1px solid rgba(120, 130, 140, .13)
}

.b-r {
  border-right: 1px solid rgba(120, 130, 140, .13)
}

.b-b {
  border-bottom: 1px solid rgba(120, 130, 140, .13)
}

.b-l {
  border-left: 1px solid rgba(120, 130, 140, .13)
}

.b-2x {
  border-width: 2px
}

.b-3x {
  border-width: 3px
}

.b-4x {
  border-width: 4px
}

.b-5x {
  border-width: 5px
}

.b-t-2x {
  border-top-width: 2px !important
}

.b-t-3x {
  border-top-width: 3px !important
}

.b-t-4x {
  border-top-width: 4px !important
}

.b-t-5x {
  border-top-width: 5px !important
}

.b-r-2x {
  border-right-width: 2px !important
}

.b-r-3x {
  border-right-width: 3px !important
}

.b-r-4x {
  border-right-width: 4px !important
}

.b-r-5x {
  border-right-width: 5px !important
}

.b-b-2x {
  border-bottom-width: 2px !important
}

.b-b-3x {
  border-bottom-width: 3px !important
}

.b-b-4x {
  border-bottom-width: 4px !important
}

.b-b-5x {
  border-bottom-width: 5px !important
}

.b-l-2x {
  border-left-width: 2px !important
}

.b-l-3x {
  border-left-width: 3px !important
}

.b-l-4x {
  border-left-width: 4px !important
}

.b-l-5x {
  border-left-width: 5px !important
}

.b-primary {
  border-color: #0cc2aa
}

.b-t-primary {
  border-top-color: #0cc2aa
}

.b-r-#e7f9f7 {
  border-right-color: #0cc2aa
}

.b-b-primary {
  border-bottom-color: #0cc2aa
}

.b-l-primary {
  border-left-color: #0cc2aa
}

.b-accent {
  border-color: #a88add
}

.b-t-accent {
  border-top-color: #a88add
}

.b-r-#f6f3fc {
  border-right-color: #a88add
}

.b-b-accent {
  border-bottom-color: #a88add
}

.b-l-accent {
  border-left-color: #a88add
}

.b-warn {
  border-color: #fcc100
}

.b-t-warn {
  border-top-color: #fcc100
}

.b-r-#fff9e6 {
  border-right-color: #fcc100
}

.b-b-warn {
  border-bottom-color: #fcc100
}

.b-l-warn {
  border-left-color: #fcc100
}

.b-success {
  border-color: #6cc788
}

.b-t-success {
  border-top-color: #6cc788
}

.b-r-#f0f9f3 {
  border-right-color: #6cc788
}

.b-b-success {
  border-bottom-color: #6cc788
}

.b-l-success {
  border-left-color: #6cc788
}

.b-info {
  border-color: #6887ff
}

.b-t-info {
  border-top-color: #6887ff
}

.b-r-#f0f3ff {
  border-right-color: #6887ff
}

.b-b-info {
  border-bottom-color: #6887ff
}

.b-l-info {
  border-left-color: #6887ff
}

.b-warning {
  border-color: #f77a99
}

.b-t-warning {
  border-top-color: #f77a99
}

.b-r-#fef2f5 {
  border-right-color: #f77a99
}

.b-b-warning {
  border-bottom-color: #f77a99
}

.b-l-warning {
  border-left-color: #f77a99
}

.b-danger {
  border-color: #f44455
}

.b-t-danger {
  border-top-color: #f44455
}

.b-r-#feecee {
  border-right-color: #f44455
}

.b-b-danger {
  border-bottom-color: #f44455
}

.b-l-danger {
  border-left-color: #f44455
}

.b-light {
  border-color: #f8f8f8
}

.b-t-light {
  border-top-color: #f8f8f8
}

.b-r-#fefefe {
  border-right-color: #f8f8f8
}

.b-b-light {
  border-bottom-color: #f8f8f8
}

.b-l-light {
  border-left-color: #f8f8f8
}

.b-grey {
  border-color: #424242
}

.b-t-grey {
  border-top-color: #424242
}

.b-r-#ececec {
  border-right-color: #424242
}

.b-b-grey {
  border-bottom-color: #424242
}

.b-l-grey {
  border-left-color: #424242
}

.b-dark {
  border-color: #2e3e4e
}

.b-t-dark {
  border-top-color: #2e3e4e
}

.b-r-#eaeced {
  border-right-color: #2e3e4e
}

.b-b-dark {
  border-bottom-color: #2e3e4e
}

.b-l-dark {
  border-left-color: #2e3e4e
}

.b-black {
  border-color: #2a2b3c
}

.b-t-black {
  border-top-color: #2a2b3c
}

.b-r-#eaeaec {
  border-right-color: #2a2b3c
}

.b-b-black {
  border-bottom-color: #2a2b3c
}

.b-l-black {
  border-left-color: #2a2b3c
}

.b-white {
  border-color: #fff
}

.b-t-white {
  border-top-color: #fff
}

.b-r-white {
  border-right-color: #fff
}

.b-b-white {
  border-bottom-color: #fff
}

.b-l-white {
  border-left-color: #fff
}

.b-blue {
  border-color: #2196f3
}

.b-t-blue {
  border-top-color: #2196f3
}

.b-r-#e9f5fe {
  border-right-color: #2196f3
}

.b-b-blue {
  border-bottom-color: #2196f3
}

.b-l-blue {
  border-left-color: #2196f3
}

.no-b-t {
  border-top-width: 0
}

.no-b-r {
  border-right-width: 0
}

.no-b-b {
  border-bottom-width: 0
}

.no-b-l {
  border-left-width: 0
}

.b-dashed {
  border-style: dashed !important
}

@media (max-width:767px) {
  .pos-stc-sm {
    position: static !important
  }

  .no-border-xs {
    border-width: 0
  }
}

.hover-action {
  display: none
}

.hover-rotate {
  -webkit-transition: all .2s ease-in-out .1s;
  transition: all .2s ease-in-out .1s
}

.hover-anchor:active .hover-action,
.hover-anchor:focus .hover-action,
.hover-anchor:hover .hover-action {
  display: inherit
}

.h-2x,
.h-3x,
.h-4x {
  display: -webkit-box;
  -webkit-box-orient: vertical
}

.hover-anchor:active .hover-rotate,
.hover-anchor:focus .hover-rotate,
.hover-anchor:hover .hover-rotate {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg)
}

.hover-top:focus,
.hover-top:hover {
  position: relative;
  z-index: 1000
}

.m-a {
  margin: 1rem !important
}

.m-r-lg,
.m-x-lg {
  margin-right: 3rem !important
}

.m-l-lg,
.m-x-lg {
  margin-left: 3rem !important
}

.m-t-lg,
.m-y-lg {
  margin-top: 3rem !important
}

.m-b-lg,
.m-y-lg {
  margin-bottom: 3rem !important
}

.m-a-lg {
  margin: 3rem !important
}

.m-r-md,
.m-x-md {
  margin-right: 1.5rem !important
}

.m-l-md,
.m-x-md {
  margin-left: 1.5rem !important
}

.m-t-md,
.m-y-md {
  margin-top: 1.5rem !important
}

.m-b-md,
.m-y-md {
  margin-bottom: 1.5rem !important
}

.m-a-md {
  margin: 1.5rem !important
}

.m-r-sm,
.m-x-sm {
  margin-right: .5rem !important
}

.m-l-sm,
.m-x-sm {
  margin-left: .5rem !important
}

.m-t-sm,
.m-y-sm {
  margin-top: .5rem !important
}

.m-b-sm,
.m-y-sm {
  margin-bottom: .5rem !important
}

.m-a-sm {
  margin: .5rem !important
}

.m-r-xs,
.m-x-xs {
  margin-right: .25rem !important
}

.m-l-xs,
.m-x-xs {
  margin-left: .25rem !important
}

.m-t-xs,
.m-y-xs {
  margin-top: .25rem !important
}

.m-b-xs,
.m-y-xs {
  margin-bottom: .25rem !important
}

.m-a-xs {
  margin: .25rem !important
}

.p-a {
  padding: 1rem !important
}

.p-r-lg,
.p-x-lg {
  padding-right: 3rem !important
}

.p-l-lg,
.p-x-lg {
  padding-left: 3rem !important
}

.p-t-lg,
.p-y-lg {
  padding-top: 3rem !important
}

.p-b-lg,
.p-y-lg {
  padding-bottom: 3rem !important
}

.p-a-lg {
  padding: 3rem !important
}

.p-r-md,
.p-x-md {
  padding-right: 1.5rem !important
}

.p-l-md,
.p-x-md {
  padding-left: 1.5rem !important
}

.p-t-md,
.p-y-md {
  padding-top: 1.5rem !important
}

.p-b-md,
.p-y-md {
  padding-bottom: 1.5rem !important
}

.p-a-md {
  padding: 1.5rem !important
}

.p-r-sm,
.p-x-sm {
  padding-right: .5rem !important
}

.p-l-sm,
.p-x-sm {
  padding-left: .5rem !important
}

.p-t-sm,
.p-y-sm {
  padding-top: .5rem !important
}

.p-b-sm,
.p-y-sm {
  padding-bottom: .5rem !important
}

.p-a-sm {
  padding: .5rem !important
}

.p-r-xs,
.p-x-xs {
  padding-right: .25rem !important
}

.p-l-xs,
.p-x-xs {
  padding-left: .25rem !important
}

.p-t-xs,
.p-y-xs {
  padding-top: .25rem !important
}

.p-b-xs,
.p-y-xs {
  padding-bottom: .25rem !important
}

.p-a-xs {
  padding: .25rem !important
}

.padding {
  padding: 1.5rem
}

.margin {
  margin-bottom: 1.5rem
}

.padding-out {
  margin: -1.5rem
}

@media (max-width:991px) {
  .no-border-sm {
    border-width: 0
  }

  .padding {
    padding: 1rem
  }

  .padding-out {
    margin: -1rem
  }

  .box,
  .box-color,
  .margin {
    margin-bottom: 1rem
  }
}

@media (max-width:767px) {
  .padding {
    padding: .5rem
  }

  .padding-out {
    margin: -.5rem
  }

  .box,
  .box-color,
  .margin {
    margin-bottom: .5rem
  }

  .no-padding-xs {
    padding: 0
  }
}

.no-radius {
  border-radius: 0 !important
}

.no-r-r,
.no-r-t {
  border-top-right-radius: 0
}

.no-r-b,
.no-r-r {
  border-bottom-right-radius: 0
}

.no-r-b,
.no-r-l {
  border-bottom-left-radius: 0
}

.no-r-l,
.no-r-t {
  border-top-left-radius: 0
}

.r-r,
.r-t {
  border-top-right-radius: 3px
}

.r-b,
.r-r {
  border-bottom-right-radius: 3px
}

.r-b,
.r-l {
  border-bottom-left-radius: 3px
}

.r-l,
.r-t {
  border-top-left-radius: 3px
}

.r {
  border-radius: 3px
}

.r-2x {
  border-radius: 6px
}

.r-3x {
  border-radius: 9px
}

.scrollable {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch
}

.scrollable.hover,
.scrollable.hover>.row-inner {
  overflow-y: hidden
}

.scrollable.hover>* {
  margin-top: -1px
}

.scrollable.hover:active,
.scrollable.hover:focus,
.scrollable.hover:hover {
  overflow: visible;
  overflow-y: auto
}

.scrollable.hover:active>.row-inner,
.scrollable.hover:focus>.row-inner,
.scrollable.hover:hover>.row-inner {
  overflow-y: auto
}

.smart .scrollable,
.smart .scrollable>.row-inner {
  overflow-y: auto !important
}

.scroll-x,
.scroll-y {
  overflow: hidden;
  -webkit-overflow-scrolling: touch
}

.scroll-y {
  overflow-y: auto
}

.scroll-x {
  overflow-x: auto
}

.h-1x,
.h-2x,
.h-3x,
.h-4x,
.md-btn,
.md-btn.md-fab,
.text-ellipsis {
  overflow: hidden
}

a.primary:hover {
  background-color: #0bb69f
}

a.text-primary:hover {
  color: #0bb69f
}

.text-primary,
.text-primary-hover a:hover {
  color: #0cc2aa !important
}

a.warn:hover {
  background-color: #efb700
}

a.text-warn:hover {
  color: #efb700
}

.text-warn,
.text-warn-hover a:hover {
  color: #fcc100 !important
}

a.accent:hover {
  background-color: #a180da
}

a.text-accent:hover {
  color: #a180da
}

.text-accent,
.text-accent-hover a:hover {
  color: #a88add !important
}

a.success:hover {
  background-color: #63c381
}

a.text-success:hover {
  color: #63c381
}

.text-success,
.text-success-hover a:hover {
  color: #6cc788 !important
}

a.info:hover {
  background-color: #5b7dff
}

a.text-info:hover {
  color: #5b7dff
}

.text-info,
.text-info-hover a:hover {
  color: #6887ff !important
}

a.warning:hover {
  background-color: #f66e90
}

a.text-warning:hover {
  color: #f66e90
}

.text-warning,
.text-warning-hover a:hover {
  color: #f77a99 !important
}

a.danger:hover {
  background-color: #f3384a
}

a.text-danger:hover {
  color: #f3384a
}

.text-danger,
.text-danger-hover a:hover {
  color: #f44455 !important
}

a.dark:hover {
  background-color: #293846
}

a.text-dark:hover {
  color: #293846
}

.text-dark,
.text-dark-hover a:hover {
  color: #2e3e4e !important
}

a.blue:hover {
  background-color: #1590f2
}

a.text-blue:hover {
  color: #1590f2
}

.text-blue,
.text-blue-hover a:hover {
  color: #2196f3 !important
}

.text-white {
  color: #fff !important
}

.text-white-lt {
  color: rgba(255, 255, 255, .54)
}

.text-white-dk {
  color: rgba(255, 255, 255, .87)
}

.text-black {
  color: #000
}

.text-black-lt {
  color: rgba(0, 0, 0, .54)
}

.text-black-dk {
  color: rgba(0, 0, 0, .87)
}

.text-muted {
  color: inherit !important;
  opacity: .6
}

.text-color {
  color: rgba(0, 0, 0, .87)
}

._100 {
  font-weight: 100
}

._200 {
  font-weight: 200
}

._300 {
  font-weight: 300
}

._400 {
  font-weight: 400
}

._500 {
  font-weight: 500
}

._600 {
  font-weight: 600
}

._700 {
  font-weight: 700
}

._800 {
  font-weight: 800
}

.text {
  font-size: 1rem
}

.text-xs {
  font-size: .75rem
}

.text-sm {
  font-size: .8rem
}

.text-md {
  font-size: 1.125rem
}

.text-lg {
  font-size: 1.5rem
}

.text-2x {
  font-size: 2em
}

.text-3x {
  font-size: 3em
}

.text-4x {
  font-size: 4em
}

.l-h {
  line-height: 1.5
}

.l-h-1x {
  line-height: 1
}

.l-h-2x {
  line-height: 2em
}

.l-s-1x {
  letter-spacing: 1px
}

.l-s-2x {
  letter-spacing: 2px
}

.l-s-3x {
  letter-spacing: 3px
}

.l-s-4x {
  letter-spacing: 4px
}

.l-s-n-1x {
  letter-spacing: -1px
}

.l-s-n-2x {
  letter-spacing: -2px
}

.l-s-n-3x {
  letter-spacing: -3px
}

.l-s-n-4x {
  letter-spacing: -4px
}

.h-1x {
  height: 1.5
}

.h-2x {
  height: 2.625rem;
  -webkit-line-clamp: 2
}

.h-3x {
  height: 3.9375rem;
  -webkit-line-clamp: 3
}

.h-4x {
  height: 5.25rem;
  -webkit-line-clamp: 4
}

.text-l-t {
  text-decoration: line-through
}

.text-u-l {
  text-decoration: underline
}

.md-btn,
.md-btn:hover {
  text-decoration: none
}

.text-ellipsis {
  display: block
}

.md-btn,
.md-check,
.md-input~label,
.w-16,
.w-20,
.w-24,
.w-32,
.w-48,
.w-56,
.w-8 {
  display: inline-block
}

.text-shadow {
  text-shadow: 0 1px 0 rgba(0, 0, 0, .1)
}

.w-8 {
  width: 8px;
  height: 8px
}

.w-16 {
  width: 16px;
  height: 16px
}

.w-20 {
  width: 20px;
  height: 20px
}

.w-24 {
  width: 24px;
  height: 24px
}

.w-32 {
  width: 32px;
  height: 32px;
  line-height: 32px
}

.w-40 {
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block
}

.w-48 {
  width: 48px;
  height: 48px;
  line-height: 48px
}

.w-56 {
  width: 56px;
  height: 56px;
  line-height: 56px
}

.w-64 {
  width: 64px
}

.w-96 {
  width: 96px
}

.w-128 {
  width: 128px
}

.w-xxs {
  width: 60px
}

.w-xs {
  width: 90px
}

.w-sm {
  width: 120px
}

.w {
  width: 180px
}

.w-md {
  width: 200px
}

.w-lg {
  width: 240px
}

.w-xl {
  width: 280px
}

.w-xxl {
  width: 320px
}

.w-full {
  width: 100%
}

.w-auto {
  width: auto
}

.h-auto {
  height: auto
}

.h-full {
  height: 100%
}

.h-v {
  height: 100vh
}

.h-v-5 {
  height: 50vh
}

@media (max-width:767px) {
  .w-auto-sm {
    width: auto
  }

  .w-full-sm {
    width: 100%
  }
}

@media (max-width:575px) {
  .w-auto-xs {
    width: auto
  }

  .w-full-xs {
    width: 100%
  }
}

.md-btn {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  font-weight: 700;
  font-style: inherit;
  font-variant: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  transition: box-shadow .4s cubic-bezier(.25, .8, .25, 1), background-color .4s cubic-bezier(.25, .8, .25, 1), -webkit-transform .4s cubic-bezier(.25, .8, .25, 1);
  transition: box-shadow .4s cubic-bezier(.25, .8, .25, 1), background-color .4s cubic-bezier(.25, .8, .25, 1), transform .4s cubic-bezier(.25, .8, .25, 1)
}

.md-flat {
  background: 0 0
}

.md-btn.md-flat:not([disabled]):focus,
.md-btn.md-flat:not([disabled]):hover {
  background-color: rgba(158, 158, 158, .2)
}

.md-btn-circle {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  padding: 2px
}

.md-btn.md-fab[disabled],
.md-btn.md-raised[disabled],
.md-btn[disabled] {
  color: rgba(0, 0, 0, .26);
  background-color: transparent;
  cursor: not-allowed
}

.md-fab {
  line-height: 44px
}

.md-fab-offset {
  margin-top: -20px;
  margin-bottom: -20px
}

.md-btn.md-cornered {
  border-radius: 0
}

.md-btn.md-icon {
  padding: 0;
  background: 0 0
}

.md-btn.md-raised {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.md-btn.md-fab {
  z-index: 20;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition: .2s linear;
  transition-property: -webkit-transform, box-shadow;
  transition-property: transform, box-shadow
}

.md-btn.md-fab,
.md-btn.md-raised:not([disabled]) {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .26)
}

.md-btn.md-fab:not([disabled]):focus,
.md-btn.md-fab:not([disabled]):hover,
.md-btn.md-raised:not([disabled]):focus,
.md-btn.md-raised:not([disabled]):hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .4);
  -webkit-transform: translate3d(0, -1px, 0);
  transform: translate3d(0, -1px, 0)
}

.md-fab-bottom-right {
  top: auto;
  right: 20px;
  bottom: 20px;
  left: auto;
  position: absolute
}

.md-fab-bottom-left {
  top: auto;
  right: auto;
  bottom: 20px;
  left: 20px;
  position: absolute
}

.md-fab-top-left,
.md-fab-top-right {
  top: 20px;
  bottom: auto;
  position: absolute
}

.md-fab-top-right {
  right: 20px;
  left: auto
}

.md-fab-top-left {
  right: auto;
  left: 20px
}

.md-btn.md-fab.md-mini {
  width: 40px;
  height: 40px;
  line-height: 28px
}

.red {
  background-color: #f44336;
  color: rgba(255, 255, 255, .87)
}

.red-100,
.red-200,
.red-300,
.red-400,
.red-50 {
  color: rgba(0, 0, 0, .87)
}

.red-50 {
  background-color: #ffebee
}

.red-100 {
  background-color: #ffcdd2
}

.red-200 {
  background-color: #ef9a9a
}

.red-300 {
  background-color: #e57373
}

.red-400 {
  background-color: #ef5350
}

.red-500,
.red-600,
.red-700,
.red-800,
.red-900 {
  color: rgba(255, 255, 255, .87)
}

.red-500 {
  background-color: #f44336
}

.red-600 {
  background-color: #e53935
}

.red-700 {
  background-color: #d32f2f
}

.red-800 {
  background-color: #c62828
}

.red-900 {
  background-color: #b71c1c
}

.red-A100 {
  background-color: #ff8a80;
  color: rgba(0, 0, 0, .87)
}

.pink,
.red-A200,
.red-A400,
.red-A700 {
  color: rgba(255, 255, 255, .87)
}

.red-A200 {
  background-color: #ff5252
}

.red-A400 {
  background-color: #ff1744
}

.red-A700 {
  background-color: #d50000
}

.pink {
  background-color: #e91e63
}

.pink-100,
.pink-200,
.pink-300,
.pink-400,
.pink-50 {
  color: rgba(0, 0, 0, .87)
}

.pink-50 {
  background-color: #fce4ec
}

.pink-100 {
  background-color: #f8bbd0
}

.pink-200 {
  background-color: #f48fb1
}

.pink-300 {
  background-color: #f06292
}

.pink-400 {
  background-color: #ec407a
}

.pink-500,
.pink-600,
.pink-700,
.pink-800,
.pink-900 {
  color: rgba(255, 255, 255, .87)
}

.pink-500 {
  background-color: #e91e63
}

.pink-600 {
  background-color: #d81b60
}

.pink-700 {
  background-color: #c2185b
}

.pink-800 {
  background-color: #ad1457
}

.pink-900 {
  background-color: #880e4f
}

.pink-A100 {
  background-color: #ff80ab;
  color: rgba(0, 0, 0, .87)
}

.pink-A200,
.pink-A400,
.pink-A700,
.purple {
  color: rgba(255, 255, 255, .87)
}

.pink-A200 {
  background-color: #ff4081
}

.pink-A400 {
  background-color: #f50057
}

.pink-A700 {
  background-color: #c51162
}

.purple {
  background-color: #9c27b0
}

.purple-100,
.purple-200,
.purple-50 {
  color: rgba(0, 0, 0, .87)
}

.purple-50 {
  background-color: #f3e5f5
}

.purple-100 {
  background-color: #e1bee7
}

.purple-200 {
  background-color: #ce93d8
}

.purple-300,
.purple-400,
.purple-500,
.purple-600,
.purple-700,
.purple-800,
.purple-900 {
  color: rgba(255, 255, 255, .87)
}

.purple-300 {
  background-color: #ba68c8
}

.purple-400 {
  background-color: #ab47bc
}

.purple-500 {
  background-color: #9c27b0
}

.purple-600 {
  background-color: #8e24aa
}

.purple-700 {
  background-color: #7b1fa2
}

.purple-800 {
  background-color: #6a1b9a
}

.purple-900 {
  background-color: #4a148c
}

.purple-A100 {
  background-color: #ea80fc;
  color: rgba(0, 0, 0, .87)
}

.deep-purple,
.purple-A200,
.purple-A400,
.purple-A700 {
  color: rgba(255, 255, 255, .87)
}

.purple-A200 {
  background-color: #e040fb
}

.purple-A400 {
  background-color: #d500f9
}

.purple-A700 {
  background-color: #a0f
}

.deep-purple {
  background-color: #673ab7
}

.deep-purple-50 {
  background-color: #ede7f6;
  color: rgba(0, 0, 0, .87)
}

.deep-purple-100 {
  background-color: #d1c4e9;
  color: rgba(0, 0, 0, .87)
}

.deep-purple-200 {
  background-color: #b39ddb;
  color: rgba(0, 0, 0, .87)
}

.deep-purple-300,
.deep-purple-400,
.deep-purple-500,
.deep-purple-600,
.deep-purple-700,
.deep-purple-800,
.deep-purple-900 {
  color: rgba(255, 255, 255, .87)
}

.deep-purple-300 {
  background-color: #9575cd
}

.deep-purple-400 {
  background-color: #7e57c2
}

.deep-purple-500 {
  background-color: #673ab7
}

.deep-purple-600 {
  background-color: #5e35b1
}

.deep-purple-700 {
  background-color: #512da8
}

.deep-purple-800 {
  background-color: #4527a0
}

.deep-purple-900 {
  background-color: #311b92
}

.deep-purple-A100 {
  background-color: #b388ff;
  color: rgba(0, 0, 0, .87)
}

.deep-purple-A200,
.deep-purple-A400,
.deep-purple-A700,
.indigo {
  color: rgba(255, 255, 255, .87)
}

.deep-purple-A200 {
  background-color: #7c4dff
}

.deep-purple-A400 {
  background-color: #651fff
}

.deep-purple-A700 {
  background-color: #6200ea
}

.indigo {
  background-color: #3f51b5
}

.indigo-100,
.indigo-200,
.indigo-50 {
  color: rgba(0, 0, 0, .87)
}

.indigo-50 {
  background-color: #e8eaf6
}

.indigo-100 {
  background-color: #c5cae9
}

.indigo-200 {
  background-color: #9fa8da
}

.indigo-300,
.indigo-400,
.indigo-500,
.indigo-600,
.indigo-700,
.indigo-800,
.indigo-900 {
  color: rgba(255, 255, 255, .87)
}

.indigo-300 {
  background-color: #7986cb
}

.indigo-400 {
  background-color: #5c6bc0
}

.indigo-500 {
  background-color: #3f51b5
}

.indigo-600 {
  background-color: #3949ab
}

.indigo-700 {
  background-color: #303f9f
}

.indigo-800 {
  background-color: #283593
}

.indigo-900 {
  background-color: #1a237e
}

.indigo-A100 {
  background-color: #8c9eff;
  color: rgba(0, 0, 0, .87)
}

.blue,
.indigo-A200,
.indigo-A400,
.indigo-A700 {
  color: rgba(255, 255, 255, .87)
}

.indigo-A200 {
  background-color: #536dfe
}

.indigo-A400 {
  background-color: #3d5afe
}

.indigo-A700 {
  background-color: #304ffe
}

.blue-100,
.blue-200,
.blue-300,
.blue-400,
.blue-50 {
  color: rgba(0, 0, 0, .87)
}

.blue-50 {
  background-color: #e3f2fd
}

.blue-100 {
  background-color: #bbdefb
}

.blue-200 {
  background-color: #90caf9
}

.blue-300 {
  background-color: #64b5f6
}

.blue-400 {
  background-color: #42a5f5
}

.blue-500,
.blue-600,
.blue-700,
.blue-800,
.blue-900 {
  color: rgba(255, 255, 255, .87)
}

.blue-500 {
  background-color: #2196f3
}

.blue-600 {
  background-color: #1e88e5
}

.blue-700 {
  background-color: #1976d2
}

.blue-800 {
  background-color: #1565c0
}

.blue-900 {
  background-color: #0d47a1
}

.blue-A100 {
  background-color: #82b1ff;
  color: rgba(0, 0, 0, .87)
}

.blue-A200,
.blue-A400,
.blue-A700,
.light-blue {
  color: rgba(255, 255, 255, .87)
}

.blue-A200 {
  background-color: #448aff
}

.blue-A400 {
  background-color: #2979ff
}

.blue-A700 {
  background-color: #2962ff
}

.light-blue {
  background-color: #03a9f4
}

.light-blue-100,
.light-blue-200,
.light-blue-300,
.light-blue-400,
.light-blue-50 {
  color: rgba(0, 0, 0, .87)
}

.light-blue-50 {
  background-color: #e1f5fe
}

.light-blue-100 {
  background-color: #b3e5fc
}

.light-blue-200 {
  background-color: #81d4fa
}

.light-blue-300 {
  background-color: #4fc3f7
}

.light-blue-400 {
  background-color: #29b6f6
}

.light-blue-500,
.light-blue-600,
.light-blue-700,
.light-blue-800,
.light-blue-900 {
  color: rgba(255, 255, 255, .87)
}

.light-blue-500 {
  background-color: #03a9f4
}

.light-blue-600 {
  background-color: #039be5
}

.light-blue-700 {
  background-color: #0288d1
}

.light-blue-800 {
  background-color: #0277bd
}

.light-blue-900 {
  background-color: #01579b
}

.light-blue-A100 {
  background-color: #80d8ff;
  color: rgba(0, 0, 0, .87)
}

.light-blue-A200 {
  background-color: #40c4ff;
  color: rgba(0, 0, 0, .87)
}

.light-blue-A400 {
  background-color: #00b0ff;
  color: rgba(0, 0, 0, .87)
}

.cyan,
.light-blue-A700 {
  color: rgba(255, 255, 255, .87)
}

.light-blue-A700 {
  background-color: #0091ea
}

.cyan {
  background-color: #00bcd4
}

.cyan-100,
.cyan-200,
.cyan-300,
.cyan-400,
.cyan-50 {
  color: rgba(0, 0, 0, .87)
}

.cyan-50 {
  background-color: #e0f7fa
}

.cyan-100 {
  background-color: #b2ebf2
}

.cyan-200 {
  background-color: #80deea
}

.cyan-300 {
  background-color: #4dd0e1
}

.cyan-400 {
  background-color: #26c6da
}

.cyan-500,
.cyan-600,
.cyan-700,
.cyan-800,
.cyan-900 {
  color: rgba(255, 255, 255, .87)
}

.cyan-500 {
  background-color: #00bcd4
}

.cyan-600 {
  background-color: #00acc1
}

.cyan-700 {
  background-color: #0097a7
}

.cyan-800 {
  background-color: #00838f
}

.cyan-900 {
  background-color: #006064
}

.cyan-A100,
.cyan-A200,
.cyan-A400,
.cyan-A700 {
  color: rgba(0, 0, 0, .87)
}

.cyan-A100 {
  background-color: #84ffff
}

.cyan-A200 {
  background-color: #18ffff
}

.cyan-A400 {
  background-color: #00e5ff
}

.cyan-A700 {
  background-color: #00b8d4
}

.teal {
  background-color: #009688;
  color: rgba(255, 255, 255, .87)
}

.teal-100,
.teal-200,
.teal-300,
.teal-400,
.teal-50 {
  color: rgba(0, 0, 0, .87)
}

.teal-50 {
  background-color: #e0f2f1
}

.teal-100 {
  background-color: #b2dfdb
}

.teal-200 {
  background-color: #80cbc4
}

.teal-300 {
  background-color: #4db6ac
}

.teal-400 {
  background-color: #26a69a
}

.teal-500,
.teal-600,
.teal-700,
.teal-800,
.teal-900 {
  color: rgba(255, 255, 255, .87)
}

.teal-500 {
  background-color: #009688
}

.teal-600 {
  background-color: #00897b
}

.teal-700 {
  background-color: #00796b
}

.teal-800 {
  background-color: #00695c
}

.teal-900 {
  background-color: #004d40
}

.teal-A100,
.teal-A200,
.teal-A400,
.teal-A700 {
  color: rgba(0, 0, 0, .87)
}

.teal-A100 {
  background-color: #a7ffeb
}

.teal-A200 {
  background-color: #64ffda
}

.teal-A400 {
  background-color: #1de9b6
}

.teal-A700 {
  background-color: #00bfa5
}

.green {
  background-color: #4caf50;
  color: rgba(255, 255, 255, .87)
}

.green-100,
.green-200,
.green-300,
.green-400,
.green-50 {
  color: rgba(0, 0, 0, .87)
}

.green-50 {
  background-color: #e8f5e9
}

.green-100 {
  background-color: #c8e6c9
}

.green-200 {
  background-color: #a5d6a7
}

.green-300 {
  background-color: #81c784
}

.green-400 {
  background-color: #66bb6a
}

.green-500,
.green-600,
.green-700,
.green-800,
.green-900 {
  color: rgba(255, 255, 255, .87)
}

.green-500 {
  background-color: #4caf50
}

.green-600 {
  background-color: #43a047
}

.green-700 {
  background-color: #388e3c
}

.green-800 {
  background-color: #2e7d32
}

.green-900 {
  background-color: #1b5e20
}

.green-A100,
.green-A200,
.green-A400,
.green-A700,
.light-green,
.light-green-200,
.light-green-300,
.light-green-400,
.light-green-50,
.light-green-500,
.light-green-600,
.light-green-700 {
  color: rgba(0, 0, 0, .87)
}

.green-A100 {
  background-color: #b9f6ca
}

.green-A200 {
  background-color: #69f0ae
}

.green-A400 {
  background-color: #00e676
}

.green-A700 {
  background-color: #00c853
}

.light-green {
  background-color: #8bc34a
}

.light-green-50 {
  background-color: #f1f8e9
}

.light-green-100 {
  background-color: #dcedc8;
  color: rgba(0, 0, 0, .87)
}

.light-green-200 {
  background-color: #c5e1a5
}

.light-green-300 {
  background-color: #aed581
}

.light-green-400 {
  background-color: #9ccc65
}

.light-green-500 {
  background-color: #8bc34a
}

.light-green-600 {
  background-color: #7cb342
}

.light-green-700 {
  background-color: #689f38
}

.light-green-800 {
  background-color: #558b2f;
  color: rgba(255, 255, 255, .87)
}

.light-green-900 {
  background-color: #33691e;
  color: rgba(255, 255, 255, .87)
}

.light-green-A100,
.light-green-A200,
.light-green-A400,
.light-green-A700,
.lime,
.lime-200,
.lime-300,
.lime-400,
.lime-50,
.lime-500,
.lime-600,
.lime-700,
.lime-800 {
  color: rgba(0, 0, 0, .87)
}

.light-green-A100 {
  background-color: #ccff90
}

.light-green-A200 {
  background-color: #b2ff59
}

.light-green-A400 {
  background-color: #76ff03
}

.light-green-A700 {
  background-color: #64dd17
}

.lime {
  background-color: #cddc39
}

.lime-50 {
  background-color: #f9fbe7
}

.lime-100 {
  background-color: #f0f4c3;
  color: rgba(0, 0, 0, .87)
}

.lime-200 {
  background-color: #e6ee9c
}

.lime-300 {
  background-color: #dce775
}

.lime-400 {
  background-color: #d4e157
}

.lime-500 {
  background-color: #cddc39
}

.lime-600 {
  background-color: #c0ca33
}

.lime-700 {
  background-color: #afb42b
}

.lime-800 {
  background-color: #9e9d24
}

.lime-900 {
  background-color: #827717;
  color: rgba(255, 255, 255, .87)
}

.amber,
.amber-200,
.amber-300,
.amber-400,
.amber-50,
.amber-500,
.amber-600,
.amber-700,
.amber-800,
.amber-900,
.amber-A100,
.amber-A200,
.amber-A400,
.amber-A700,
.lime-A100,
.lime-A200,
.lime-A400,
.lime-A700,
.orange,
.orange-100,
.orange-200,
.orange-300,
.orange-400,
.orange-50,
.orange-500,
.orange-600,
.orange-700,
.yellow,
.yellow-100,
.yellow-200,
.yellow-300,
.yellow-400,
.yellow-50,
.yellow-500,
.yellow-600,
.yellow-700,
.yellow-800,
.yellow-900,
.yellow-A100,
.yellow-A200,
.yellow-A400,
.yellow-A700 {
  color: rgba(0, 0, 0, .87)
}

.lime-A100 {
  background-color: #f4ff81
}

.lime-A200 {
  background-color: #eeff41
}

.lime-A400 {
  background-color: #c6ff00
}

.lime-A700 {
  background-color: #aeea00
}

.yellow {
  background-color: #ffeb3b
}

.yellow-50 {
  background-color: #fffde7
}

.yellow-100 {
  background-color: #fff9c4
}

.yellow-200 {
  background-color: #fff59d
}

.yellow-300 {
  background-color: #fff176
}

.yellow-400 {
  background-color: #ffee58
}

.yellow-500 {
  background-color: #ffeb3b
}

.yellow-600 {
  background-color: #fdd835
}

.yellow-700 {
  background-color: #fbc02d
}

.yellow-800 {
  background-color: #f9a825
}

.yellow-900 {
  background-color: #f57f17
}

.yellow-A100 {
  background-color: #ffff8d
}

.yellow-A200 {
  background-color: #ff0
}

.yellow-A400 {
  background-color: #ffea00
}

.yellow-A700 {
  background-color: #ffd600
}

.amber {
  background-color: #ffc107
}

.amber-50 {
  background-color: #fff8e1
}

.amber-100 {
  background-color: #ffecb3;
  color: rgba(0, 0, 0, .87)
}

.amber-200 {
  background-color: #ffe082
}

.amber-300 {
  background-color: #ffd54f
}

.amber-400 {
  background-color: #ffca28
}

.amber-500 {
  background-color: #ffc107
}

.amber-600 {
  background-color: #ffb300
}

.amber-700 {
  background-color: #ffa000
}

.amber-800 {
  background-color: #ff8f00
}

.amber-900 {
  background-color: #ff6f00
}

.amber-A100 {
  background-color: #ffe57f
}

.amber-A200 {
  background-color: #ffd740
}

.amber-A400 {
  background-color: #ffc400
}

.amber-A700 {
  background-color: #ffab00
}

.orange {
  background-color: #ff9800
}

.orange-50 {
  background-color: #fff3e0
}

.orange-100 {
  background-color: #ffe0b2
}

.orange-200 {
  background-color: #ffcc80
}

.orange-300 {
  background-color: #ffb74d
}

.orange-400 {
  background-color: #ffa726
}

.orange-500 {
  background-color: #ff9800
}

.orange-600 {
  background-color: #fb8c00
}

.orange-700 {
  background-color: #f57c00
}

.orange-800,
.orange-900 {
  color: rgba(255, 255, 255, .87)
}

.orange-800 {
  background-color: #ef6c00
}

.orange-900 {
  background-color: #e65100
}

.orange-A100,
.orange-A200,
.orange-A400,
.orange-A700 {
  color: rgba(0, 0, 0, .87)
}

.orange-A100 {
  background-color: #ffd180
}

.orange-A200 {
  background-color: #ffab40
}

.orange-A400 {
  background-color: #ff9100
}

.orange-A700 {
  background-color: #ff6d00
}

.deep-orange {
  background-color: #ff5722;
  color: rgba(255, 255, 255, .87)
}

.deep-orange-100,
.deep-orange-200,
.deep-orange-300,
.deep-orange-400,
.deep-orange-50 {
  color: rgba(0, 0, 0, .87)
}

.deep-orange-50 {
  background-color: #fbe9e7
}

.deep-orange-100 {
  background-color: #ffccbc
}

.deep-orange-200 {
  background-color: #ffab91
}

.deep-orange-300 {
  background-color: #ff8a65
}

.deep-orange-400 {
  background-color: #ff7043
}

.deep-orange-500,
.deep-orange-600,
.deep-orange-700,
.deep-orange-800,
.deep-orange-900 {
  color: rgba(255, 255, 255, .87)
}

.deep-orange-500 {
  background-color: #ff5722
}

.deep-orange-600 {
  background-color: #f4511e
}

.deep-orange-700 {
  background-color: #e64a19
}

.deep-orange-800 {
  background-color: #d84315
}

.deep-orange-900 {
  background-color: #bf360c
}

.deep-orange-A100 {
  background-color: #ff9e80;
  color: rgba(0, 0, 0, .87)
}

.deep-orange-A200 {
  background-color: #ff6e40;
  color: rgba(0, 0, 0, .87)
}

.brown,
.deep-orange-A400,
.deep-orange-A700 {
  color: rgba(255, 255, 255, .87)
}

.deep-orange-A400 {
  background-color: #ff3d00
}

.deep-orange-A700 {
  background-color: #dd2c00
}

.brown {
  background-color: #795548
}

.brown-100,
.brown-200,
.brown-50 {
  color: rgba(0, 0, 0, .87)
}

.brown-50 {
  background-color: #efebe9
}

.brown-100 {
  background-color: #d7ccc8
}

.brown-200 {
  background-color: #bcaaa4
}

.blue-grey,
.brown-300,
.brown-400,
.brown-500,
.brown-600,
.brown-700,
.brown-800,
.brown-900 {
  color: rgba(255, 255, 255, .87)
}

.brown-300 {
  background-color: #a1887f
}

.brown-400 {
  background-color: #8d6e63
}

.brown-500 {
  background-color: #795548
}

.brown-600 {
  background-color: #6d4c41
}

.brown-700 {
  background-color: #5d4037
}

.brown-800 {
  background-color: #4e342e
}

.brown-900 {
  background-color: #3e2723
}

.blue-grey {
  background-color: #607d8b
}

.blue-grey-100,
.blue-grey-200,
.blue-grey-300,
.blue-grey-50 {
  color: rgba(0, 0, 0, .87)
}

.blue-grey-50 {
  background-color: #eceff1
}

.blue-grey-100 {
  background-color: #cfd8dc
}

.blue-grey-200 {
  background-color: #b0bec5
}

.blue-grey-300 {
  background-color: #90a4ae
}

.blue-grey-400,
.blue-grey-500,
.blue-grey-600,
.blue-grey-700,
.blue-grey-800,
.blue-grey-900 {
  color: rgba(255, 255, 255, .87)
}

.blue-grey-400 {
  background-color: #78909c
}

.blue-grey-500 {
  background-color: #607d8b
}

.blue-grey-600 {
  background-color: #546e7a
}

.blue-grey-700 {
  background-color: #455a64
}

.blue-grey-800 {
  background-color: #37474f
}

.blue-grey-900 {
  background-color: #263238
}

.grey-100,
.grey-200,
.grey-300,
.grey-400,
.grey-50,
.grey-500 {
  color: rgba(0, 0, 0, .87)
}

.grey-50 {
  background-color: #fafafa
}

.grey-100 {
  background-color: #f5f5f5
}

.grey-200 {
  background-color: #eee
}

.grey-300 {
  background-color: #e0e0e0
}

.grey-400 {
  background-color: #bdbdbd
}

.grey-500 {
  background-color: #9e9e9e
}

.grey-600,
.grey-700,
.grey-800,
.grey-900 {
  color: rgba(255, 255, 255, .87)
}

.grey-600 {
  background-color: #757575
}

.grey-700 {
  background-color: #616161
}

.grey-800 {
  background-color: #424242
}

.grey-900 {
  background-color: #212121
}

.md-form-group {
  padding: 18px 0 24px;
  position: relative
}

.md-input {
  background: 0 0;
  position: relative;
  z-index: 5;
  border: 0;
  box-shadow: none;
  border-radius: 0;
  border-bottom: 1px solid rgba(160, 160, 160, .2);
  width: 100%;
  height: 34px;
  padding: 2px;
  color: inherit
}

.md-input.focus,
.md-input:focus {
  border-color: #0cc2aa;
  border-bottom-width: 2px;
  padding-bottom: 1px
}

.md-input.focus~label,
.md-input:focus~label {
  color: #0cc2aa;
  opacity: 1;
  top: 0 !important;
  font-size: .85em !important
}

.float-label .md-input~label {
  top: 20px;
  font-size: 1em
}

.float-label .md-input.has-value~label,
.float-label .md-input.ng-dirty~label,
.md-input~label {
  top: 0;
  font-size: .85em
}

.md-input~label {
  -webkit-transition: all .2s;
  transition: all .2s;
  position: absolute;
  z-index: 0;
  opacity: .5;
  left: 0
}

.md-input.disabled,
.md-input[disabled] {
  opacity: .5
}

textarea.md-input {
  height: auto
}

.md-input-white.focus,
.md-input-white:focus {
  border-color: #fff
}

.md-input-white.focus~label,
.md-input-white:focus~label {
  color: #fff
}

.md-input-msg {
  position: absolute;
  bottom: 0;
  line-height: 24px;
  font-size: .85em
}

.md-check>i,
.md-switch i {
  line-height: 1;
  vertical-align: middle
}

.md-input-msg.right {
  right: 0
}

.md-check {
  cursor: pointer;
  padding-left: 1.25rem;
  margin: 0;
  position: relative
}

.md-check input {
  position: absolute;
  cursor: pointer;
  z-index: 1;
  opacity: 0;
  margin-left: -1.25rem
}

.md-check input:checked+i:before {
  border-width: 0;
  background-color: inherit
}

.md-check input:checked+span .active {
  display: inherit
}

.md-check input[type=radio]+i,
.md-check input[type=radio]+i:before {
  border-radius: 50%
}

.md-check input[type=checkbox]:checked+i:after {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  left: 6px;
  top: 2px;
  display: table;
  width: 6px;
  height: 12px;
  border: 2px solid;
  border-top: 0;
  border-left: 0;
  content: ' '
}

.md-check input[type=radio]:checked+i:after {
  position: absolute;
  left: 6px;
  top: 6px;
  display: table;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
  content: ' '
}

.md-check>i:before,
.md-switch i:after,
.md-switch i:before {
  content: ""
}

.md-check input[disabled]+i:before,
.md-check input[disabled]:checked+i:before,
fieldset[disabled] .md-check input+i:before {
  opacity: .5
}

.md-check>i {
  width: 0;
  height: 18px;
  margin-left: -20px;
  margin-top: 1px;
  float: left;
  display: inline-block;
  margin-right: 28px;
  position: relative
}

.md-check>i:before {
  position: absolute;
  width: 18px;
  height: 100%;
  border: 2px solid #9e9e9e;
  border-radius: 2px
}

.md-check>i.no-icon:after {
  display: none !important
}

.md-switch {
  cursor: pointer;
  padding-left: 36px;
  margin: 0;
  min-height: 20px
}

.md-switch input {
  position: absolute;
  cursor: pointer;
  width: 36px;
  height: 20px;
  z-index: 1;
  opacity: 0;
  margin-left: -36px
}

.md-switch input:checked+i:before {
  background: inherit;
  opacity: .5
}

.md-switch input:checked+i:after {
  background: inherit;
  left: 16px
}

.md-switch input[disabled]+i:before,
fieldset[disabled] .md-switch input+i:before {
  background-color: rgba(0, 0, 0, .12)
}

.md-switch input[disabled]+i:after,
fieldset[disabled] .md-switch input+i:after {
  background-color: #bdbdbd
}

.md-switch i {
  width: 0;
  height: 18px;
  margin-left: -36px;
  margin-top: -2px;
  display: inline-block;
  margin-right: 44px;
  position: relative
}

.md-switch i:before {
  left: 1px;
  width: 34px;
  top: 3px;
  height: 14px;
  border-radius: 8px;
  position: absolute;
  background-color: #9e9e9e;
  -webkit-transition: all .2s;
  transition: all .2s
}

.md-switch i:after {
  position: absolute;
  margin: 0;
  left: 0;
  top: 0;
  outline: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .26);
  -webkit-transition: all .2s;
  transition: all .2s
}

.animated {
  -webkit-animation-duration: .5s;
  animation-duration: .5s
}

[ui-view].ng-leave {
  display: none
}

[ui-view].ng-leave.smooth {
  display: block
}

.smooth.ng-animate {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden
}

.fade-in-right-big.ng-enter {
  -webkit-animation: fadeInRightBig .5s;
  animation: fadeInRightBig .5s
}

.fade-in-right-big.ng-leave {
  -webkit-animation: fadeOutLeftBig .5s;
  animation: fadeOutLeftBig .5s
}

.fade-in-left-big.ng-enter {
  -webkit-animation: fadeInLeftBig .5s;
  animation: fadeInLeftBig .5s
}

.fade-in-left-big.ng-leave {
  -webkit-animation: fadeOutRightBig .5s;
  animation: fadeOutRightBig .5s
}

.fade-in-up-big.ng-enter {
  -webkit-animation: fadeInUpBig .5s;
  animation: fadeInUpBig .5s
}

.fade-in-up-big.ng-leave {
  -webkit-animation: fadeOutUpBig .5s;
  animation: fadeOutUpBig .5s
}

.fade-in-down-big.ng-enter {
  -webkit-animation: fadeInDownBig .5s;
  animation: fadeInDownBig .5s
}

.fade-in-down-big.ng-leave {
  -webkit-animation: fadeOutDownBig .5s;
  animation: fadeOutDownBig .5s
}

.fade-in.ng-enter {
  -webkit-animation: fadeIn .5s;
  animation: fadeIn .5s
}

.fade-in.ng-leave {
  -webkit-animation: fadeOut .5s;
  animation: fadeOut .5s
}

.fade-in-right.ng-enter {
  -webkit-animation: fadeInRight .5s;
  animation: fadeInRight .5s
}

.fade-in-right.ng-leave {
  -webkit-animation: fadeOutLeft .5s;
  animation: fadeOutLeft .5s
}

.fade-in-left.ng-enter {
  -webkit-animation: fadeInLeft .5s;
  animation: fadeInLeft .5s
}

.fade-in-left.ng-leave {
  -webkit-animation: fadeOutRight .5s;
  animation: fadeOutRight .5s
}

.fade-in-up.ng-enter {
  -webkit-animation: fadeInUp .5s;
  animation: fadeInUp .5s
}

.fade-in-up.ng-leave {
  -webkit-animation: fadeOutUp .5s;
  animation: fadeOutUp .5s
}

.fade-in-down.ng-enter {
  -webkit-animation: fadeInDown .5s;
  animation: fadeInDown .5s
}

.fade-in-down.ng-leave {
  -webkit-animation: fadeOutDown .5s;
  animation: fadeOutDown .5s
}
